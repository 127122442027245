<template>
  <a
    :href="linkUrl"
    :target="target"
    class="link"
    :class="{ 'link-color': !linkColor }"
    @click.exact="handleClickItem($event, false)"
    @click.ctrl="handleClickItem($event, true)"
    @click.meta="handleClickItem($event, true)"
  >
    <slot></slot>
  </a>
</template>

<script>
import mixinsLink from 'view-design/src/mixins/link'

export default {
  name: 'iLink',
  mixins: [mixinsLink],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // 开启后，链接颜色为默认的蓝色，默认关闭为继承效果
    linkColor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickItem(event, new_window = false) {
      if (this.disabled) {
        event.preventDefault()
        return
      }

      this.handleCheckClick(event, new_window)
    },
  },
}
</script>

<style lang="scss">
.link {
  cursor: pointer;
  &-color {
    &,
    &:hover,
    &:active {
      color: inherit;
    }
  }
}
</style>
