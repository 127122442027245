import request from './request'

export default {
  list(data) {
    return request.post('/page/list', data)
  },
  info(data) {
    return request.post('/page/info', data)
  },
  save(data) {
    return request.post('/page/save', data)
  },
  enable(data) {
    return request.post('/page/enable', data)
  },
  disable(data) {
    return request.post('/page/disable', data)
  },
  delete(data) {
    return request.post('/page/delete', data)
  },
}
