export default {
  path: '/content',
  title: '内容管理',
  auth: 'content',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/notice/list',
      title: '公告管理',
      auth: 'notice-list',
    },
    {
      path: '/banner/list',
      title: '海报轮播',
      auth: 'banner-list',
    },
    {
      path: '/faq/list',
      title: '帮助中心',
      auth: 'faq-list',
    },
    // {
    //   path: '/feedback/list',
    //   title: '求片中心',
    //   auth: 'feedback-list',
    // },
    {
      path: '/customer-service/list',
      title: '客服中心',
      auth: 'customer-service-list',
    },
    {
      path: '/video/comment',
      title: '评论管理',
      auth: 'video-comment',
    },
    {
      path: '/video/barrage',
      title: '弹幕管理',
      auth: 'video-barrage',
    },
    {
      path: '/floating/list',
      title: '首页悬浮',
      auth: 'floating-list',
    },
    {
      path: '/app-version/list',
      title: 'APP版本',
      auth: 'app-version-list',
    },
    // {
    //   path: '/faq/list',
    //   title: '系统通知',
    // },
    {
      path: '/page/list',
      title: '静态页面',
      auth: 'page-list',
    },
    // {
    //   path: '/faq/list',
    //   title: '弹幕管理',
    // },
    // {
    //   path: '/faq/list',
    //   title: '任务管理',
    // },
  ],
}
