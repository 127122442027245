export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '片名',
    key: 'title',
    show: true,
  },
  {
    title: '内容',
    key: 'content',
    show: true,
  },
  {
    title: '用户',
    key: 'username',
    slot: 'username',
    show: true,
  },
  {
    title: '栏目',
    key: 'parent_category_name',
    show: false,
  },
  {
    title: '类型',
    key: 'category_name',
    show: false,
  },
  {
    title: '颜色',
    key: 'color',
    slot: 'color',
    show: false,
  },
  // {
  //   title: '状态',
  //   key: 'status',
  //   slot: 'status',
  //   show: true,
  // },

  {
    title: '发送时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
