import dayjs from 'dayjs'
import { get } from 'lodash'
import storage from '@/utils/storage'

export default {
  namespaced: true,
  state: {
    logs: [],
  },
  getters: {
    length(state) {
      return state.logs.length
    },
    lengthError(state) {
      return state.logs.filter((log) => log.type === 'error').length
    },
  },
  mutations: {
    set(state, logs) {
      state.logs = logs
    },
    push(state, log) {
      state.logs.push(log)
    },
    remove(state, i) {
      state.logs.splice(i, 1)
    },
    clean(state) {
      state.logs = []
    },
  },
  actions: {
    init({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const logs = storage.logs.get()
        commit('set', logs)

        resolve()
      })
    },
    push({ commit }, { message, type = 'info', meta }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const log = {
          message,
          type,
          time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          meta: {
            url: get(window, 'location.href', ''),
            ...meta,
          },
        }
        commit('push', log)
        storage.logs.push(log)

        resolve()
      })
    },
    remove({ commit }, i) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('remove', i)
        storage.logs.remove(i)

        resolve()
      })
    },
    clean({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('clean')
        storage.logs.clean()

        resolve()
      })
    },
  },
}
