<template>
  <div>
    <!-- <ButtonGroup size="small">
      <Button type="primary" @click="handleOpenCreate">新增</Button>
    </ButtonGroup> -->
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        置未读
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        置已读
      </Button>
      <!-- <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button> -->
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="category">
        <Tag color="blue">{{ row.category_name }}</Tag>
      </template>
      <template slot-scope="{ row }" slot="user">
        <span>
          {{ row.user_username !== '' ? row.user_username : row.user_email }}
        </span>
      </template>
      <template slot-scope="{ row }" slot="content">
        <Ellipsis :text="row.content" :length="28" :lines="1" tooltip />
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="未读" />
        <Badge v-else-if="row.status === 0" color="yellow" text="已读" />
      </template>
      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button
            v-if="row.status === 1"
            type="primary"
            ghost
            @click="handleReply(row.id)"
          >
            回复
          </Button>
          <Button v-else type="primary" ghost @click="handleView(row.id)">
            查看
          </Button>
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑反馈'
          : dataDrawer.type === 'new'
          ? '添加反馈'
          : '反馈信息'
      "
      width="540"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="分类">
              <Tag color="blue">
                {{ categoryName(dataInfo.category_id) }}
              </Tag>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem label="描述">
              <p>{{ dataInfo.content }}</p>
            </FormItem>
          </Col>
        </Row>

        <Divider />

        <Row :gutter="32" v-if="dataDrawer.type === 'edit'">
          <Col span="24">
            <FormItem prop="content" label="回复内容">
              <Input
                size="small"
                v-model="replyInfo.content"
                type="textarea"
                :rows="10"
                placeholder="请输入回复内容"
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div
        class="page-sys-user-drawer-footer"
        v-if="dataDrawer.type === 'edit'"
      >
        <Button
          type="primary"
          @click="handleSubmitReply"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="showViewDrawer"
      title="反馈信息"
      width="540"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="viewDataInfo"
        disabled
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="分类">
              <Tag color="blue">
                {{ categoryName(viewDataInfo.category_id) }}
              </Tag>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem label="描述">
              <p>{{ viewDataInfo.content }}</p>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem label="反馈时间">
              <p>{{ datetimeFormat(viewDataInfo.create_time) }}</p>
            </FormItem>
          </Col>
        </Row>

        <Divider />

        <Row :gutter="32">
          <Col span="24">
            <FormItem label="回复内容">
              <p>{{ viewDataInfo.reply_content }}</p>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem label="回复时间">
              <p>{{ datetimeFormat(viewDataInfo.reply_time) }}</p>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  data() {
    return {
      datetimeFormat,
      replyInfo: {
        content: '',
      },
      categoryList: [],

      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },

      showViewDrawer: false,
      viewDataInfo: null,
    }
  },
  mixins: [dataListMixin],
  computed: {
    categoryMap() {
      let map = {}
      this.categoryList.forEach((row) => {
        map[row.id] = row.name
      })
      return map
    },
  },
  methods: {
    categoryName(id) {
      const map = this.categoryMap
      if (Object.keys(map).length < 1) {
        return '--'
      }
      return map[id]
    },
    // 取分类列表
    handleGetCategoryList() {
      api.feedback.category
        .list({
          status: 1,
        })
        .then((data) => {
          if (!data) return
          this.categoryList = data.feedback_category_list || []
        })
        .catch(() => {})
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      this.handleGetCategoryList()

      // 下面的 params 是获取的表单查询参数
      const {
        keyword,
        category_id,
        status,
        date,
        page,
      } = this.$parent.$parent.$refs.filter.params
      if (page) {
        this.page = page
        this.$parent.$parent.$refs.filter.params.page = null
      }
      api.feedback
        .list({
          keyword,
          category_id,
          status,
          start_time:
            date[0] !== '' ? this.$Date(date[0]).format('YYYY-MM-DD') : '',
          end_time:
            date[1] !== '' ? this.$Date(date[1]).format('YYYY-MM-DD') : '',
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return
          this.handleClearSelect()
          this.dataList = data.feedback_list || []
          this.dataTotal = data.feedback_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.feedback
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.feedback || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.feedback
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量封号
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.feedback
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.feedback
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.feedback
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 回复
    handleReply(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.replyInfo.content = ''

          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleView(id) {
      api.feedback
        .info({
          id,
        })
        .then((data) => {
          this.viewDataInfo = data.feedback
        })
      this.showViewDrawer = true
    },
    handleSubmitReply() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            feedback_id: this.dataInfo.id,
            content: this.replyInfo.content,
          }
          // console.log(this.dataInfo)
          api.feedback
            .reply(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('回复成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
