import request from './request'

export default {
  info(data) {
    return request.post('/admin/info', data)
  },
  saveInfo(data) {
    return request.post('/admin/saveInfo', data)
  },
}
