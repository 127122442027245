export default [
  {
    title: '日期',
    key: 'target_date',
    show: true,
  },
  {
    title: '当天注册',
    key: 'user_increment_1d',
    show: true,
  },
  {
    title: '7天注册',
    key: 'user_increment_7d',
    show: true,
  },
  {
    title: '30天注册',
    key: 'user_increment_30d',
    show: true,
  },
  {
    title: '当天活跃',
    key: 'user_active_1d',
    show: true,
  },
  {
    title: '7天活跃',
    key: 'user_active_7d',
    show: true,
  },
  {
    title: '30天活跃',
    key: 'user_active_30d',
    show: true,
  },
  {
    title: '当天充值用户',
    key: 'user_recharge_1d',
    show: true,
  },
  {
    title: '7天充值用户',
    key: 'user_recharge_7d',
    show: true,
  },
  {
    title: '30天充值用户',
    key: 'user_recharge_30d',
    show: true,
  },
  {
    title: '当天充值总额',
    key: 'payment_order_amount_increment_1d',
    show: true,
  },
  {
    title: '7天充值总额',
    key: 'payment_order_amount_increment_7d',
    show: true,
  },
  {
    title: '30天充值总额',
    key: 'payment_order_amount_increment_30d',
    show: true,
  },
]
