import request from './request'

export default {
  list(data) {
    return request.post('/ad/list', data)
  },
  info(data) {
    return request.post('/ad/info', data)
  },
  save(data) {
    return request.post('/ad/save', data)
  },
  enable(data) {
    return request.post('/ad/enable', data)
  },
  disable(data) {
    return request.post('/ad/disable', data)
  },
  delete(data) {
    return request.post('/ad/delete', data)
  },

  category(data) {
    return request.post('/ad/category', data)
  },
}
