export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    // title: '状态/编号',
    key: 'id',
    minWidth: 50,
    maxWidth: 100,
    slot: 'id',
    show: true,
  },
  {
    title: '用户名',
    key: 'username',
    slot: 'username',
    show: true,
  },
  {
    title: '邮箱',
    key: 'email',
    show: true,
  },
  {
    title: '昵称',
    key: 'nickname',
    show: false,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  // {
  //   title: '会员级别',
  //   key: 'vip',
  //   slot: 'vip',
  //   show: true,
  // },
  // {
  //   // title: '会员级别/VIP到期时间',
  //   title: 'VIP到期时间',
  //   key: 'vip_expired_time',
  //   slot: 'vip_expired_time',
  //   show: true,
  // },
  {
    title: '注册时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },
  {
    title: '最后登录时间',
    key: 'last_signin_time',
    slot: 'last_signin_time',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 150,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
