<template>
  <span
    class="layout-header-trigger"
    :class="{ 'layout-header-trigger-min': showSiderCollapse }"
    @click="handleReload"
  >
    <Icon custom="i-icon i-icon-refresh" />
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'oHeaderReload',
  computed: {
    ...mapState('admin/layout', ['isMobile', 'showSiderCollapse']),
  },
  methods: {
    handleReload() {
      this.$emit('on-reload')
    },
  },
}
</script>
