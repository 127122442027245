export default {
  // baseUrl: 'https://api-admin.uvod.tv', //线上

  // baseUrl: 'http://192.168.31.8:8114',
  // baseUrl: 'http://51.79.223.179:8114',
  baseUrl: 'https://api-admin.ub1818.com',

  openurl: 'https://www.uvod.tv', //打开播放页网址的域名
  timeout: 15000,
  channelId: '1',
  defaultLocale: 'zh-CN',
  requestWith: 'uvod-client',
  userAgent: 'uvod-client',

  debug: false,
}
