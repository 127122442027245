export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '名称',
    key: 'title',
    show: true,
    minWidth: 150,
  },
  {
    title: '栏目',
    key: 'parent_category_name',
    show: true,
  },
  {
    title: '类型',
    key: 'category_name',
    show: true,
  },
  {
    title: '添加时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
    width: 150,
  },
  {
    title: '显示状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
