import iFrame from './frame'
import iLink from './link'

const components = {
  'i-frame': iFrame,
  'i-link': iLink,
}

// eslint-disable-next-line
const install = function (Vue, opts = {}) {
  if (install.installed) return false

  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key])
  })
}

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  install,
  ...components,
}
