export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '上级',
    key: 'pid',
    slot: 'pid',
    // maxWidth: 100,
    show: true,
  },
  {
    title: '名称',
    key: 'name',
    slot: 'name',
    minWidth: 140,
    show: true,
  },
  {
    title: '图标',
    key: 'icon',
    slot: 'icon',
    minWidth: 140,
    show: true,
  },
  {
    title: '排序',
    key: 'sort',
    slot: 'sort',
    minWidth: 140,
    show: true,
  },
  {
    title: '导航显示',
    key: 'show',
    slot: 'show',
    minWidth: 140,
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    minWidth: 100,
    show: true,
  },
  // {
  //   title: '操作',
  //   key: 'action',
  //   slot: 'action',
  //   minWidth: 120,
  //   maxWidth: 160,
  //   align: 'center',
  //   fixed: 'right',
  //   show: true,
  // },
]
