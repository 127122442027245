import request from './request'

export default {
  list(data) {
    return request.post('/adCategory/list', data)
  },
  info(data) {
    return request.post('/adCategory/info', data)
  },
  save(data) {
    return request.post('/adCategory/save', data)
  },
  enable(data) {
    return request.post('/adCategory/enable', data)
  },
  disable(data) {
    return request.post('/adCategory/disable', data)
  },
  delete(data) {
    return request.post('/adCategory/delete', data)
  },
}
