<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="关键词" prop="keyword" label-for="keyword">
          <Input
            size="small"
            v-model="params.keyword"
            clearable
            placeholder="片名|用户名|评论内容"
          />
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择"
          >
            <Option value="1">显示</Option>
            <Option value="0">屏蔽</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="评论时间" prop="date" label-for="date">
          <DatePicker
            size="small"
            v-model="params.date"
            clearable
            type="daterange"
            :options="dateOptions"
            placeholder="请选择"
            v-width="'100%'"
          />
        </FormItem>
      </Col>

      <Col v-bind="grid" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'

export default {
  data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 8,
        sm: 24,
        xs: 24,
      },

      params: {
        keyword: '',
        status: '',
        date: [],
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
  },
}
</script>
