import request from './request'

export default {
  list(data) {
    return request.post('/appVersion/list', data)
  },
  info(data) {
    return request.post('/appVersion/info', data)
  },
  save(data) {
    return request.post('/appVersion/save', data)
  },
  enable(data) {
    return request.post('/appVersion/enable', data)
  },
  disable(data) {
    return request.post('/appVersion/disable', data)
  },
  delete(data) {
    return request.post('/appVersion/delete', data)
  },
}
