export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '片名',
    key: 'video_title',
    show: true,
  },
  {
    title: '内容',
    key: 'content',
    show: true,
  },
  {
    title: '用户',
    key: 'user',
    slot: 'user',
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '评论时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
