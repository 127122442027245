<template>
  <div>
    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
    >
      <template slot-scope="{ row }" slot="create_time">
        {{ row.create_time > 0 ? datetimeFormat(row.create_time) : '--' }}
      </template>
      <template slot-scope="{ row }" slot="description">
        {{ row.description }}
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import dataListMixin from '@/mixins/data-list'

export default {
  props: {
    uid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      datetimeFormat,
      // pagesize: 20,
      columns: [
        {
          title: '时间',
          key: 'create_time',
          slot: 'create_time',
          width: 200,
          show: true,
        },
        {
          title: '日志描述',
          key: 'description',
          slot: 'description',
          show: true,
        },
      ],
    }
  },
  computed: {
    // 动态设置列
    tableColumns() {
      const columns = [...this.columns]
      return columns.filter((item) => item.show)
    },
  },
  mixins: [dataListMixin],
  methods: {
    // 取数据列表
    handleGetDataList() {
      const uid = this.uid
      if (!uid) return
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      // const { keyword, status, vip } = this.$parent.$parent.$refs.filter.params
      api.user
        .activeList({
          // keyword,
          // status,
          // vip,
          uid,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.user_active_list || []
          this.dataTotal = data.user_active_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
