/**
 * 对象(键值对)
 */
import qs from 'qs'

export default {
  filter(dict) {
    let d = {}
    for (const k in dict) {
      switch (dict[k]) {
        case 0:
        case 0.0:
        case '0':
        case '':
        case false:
        case null:
        case undefined:
          break
        default:
          d[k] = dict[k]
          break
      }
    }
    return d
  },

  ksort(dict) {
    let d = {}
    const keys = Object.keys(dict).sort()
    keys.forEach((v) => {
      d[v] = dict[v]
    })
    return d
  },

  createHttpQuery(dict) {
    return qs.stringify(dict).toLowerCase()
  },
}
