export default {
  path: '/account',
  title: '个人中心',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/account/index',
      title: '用户设置',
    },
  ],
}
