<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="片源管理" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt" ref="card">
      <o-filter
        ref="filter"
        :category-list="categoryList"
        :regions="regions"
        :languages="languages"
        :years="years"
        :states="states"
        @on-submit="handleGetData"
      />
      <o-table
        ref="table"
        :category-list="categoryList"
        :regions="regions"
        :languages="languages"
        :years="years"
        :states="states"
        @on-fullscreen="handleFullscreen"
      />
    </Card>
  </div>
</template>
<script>
import screenfull from 'screenfull'
import oFilter from './filter'
import oTable from './table'
import api from '@/api'

export default {
  name: 'video-list',
  components: {
    oFilter,
    oTable,
  },
  data() {
    return {
      categoryList: [],

      regions: [],
      languages: [],
      years: [],
      states: [],
    }
  },
  methods: {
    handleGetData() {
      this.handleGetCategoryList()
      this.handleGetVideoConfig()

      this.$refs.table.handleGetDataList()
    },
    handleFullscreen(state) {
      if (state) {
        screenfull.request(this.$refs.card.$el)
      } else {
        screenfull.exit()
      }
    },
    // 取分类列表
    handleGetCategoryList() {
      api.video
        .category({
          // status: 1,
        })
        .then((data) => {
          if (!data) return
          this.categoryList = data.video_category_list || []
        })
        .catch(() => {})
    },
    // 取视频配置
    handleGetVideoConfig() {
      api.config
        .info({
          symbol: 'video',
        })
        .then((data) => {
          if (!data) return
          // console.log(data)
          this.regions = data.areas || []
          this.languages = data.languages || []
          this.years = data.years || []
          this.states = data.states || []
        })
        .catch(() => {})
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
