<template>
  <div>
    <ButtonGroup size="small">
      <Button type="primary" @click="handleOpenGenerate">生成</Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        置未使用
      </Button>
      <Button
        type="info"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        置已使用
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleForbiddenMultiple"
      >
        置禁用
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button>
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="category">
        <Tag color="blue">{{ categoryName(row.category_id) }}</Tag>
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="未使用" />
        <Badge v-else-if="row.status === 0" color="blue" text="已使用" />
        <Badge v-else-if="row.status === -1" color="yellow" text="禁用" />
      </template>
      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>
      <template slot-scope="{ row }" slot="active_time">
        {{ row.active_time > 0 ? datetimeFormat(row.active_time) : '--' }}
      </template>
      <template slot-scope="{ row }" slot="remark">
        {{ row.remark }}
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <!-- <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button> -->
          <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button>
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑广告'
          : dataDrawer.type === 'new'
          ? '添加广告'
          : '广告信息'
      "
      width="540"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="title" label="标题">
              <Input
                size="small"
                v-model="dataInfo.title"
                placeholder="请输入标题"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="status" label="状态">
              <RadioGroup size="small" v-model="dataInfo.status">
                <Radio :label="1"><span>正常</span></Radio>
                <Radio :label="0"><span>禁用</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>

    <Drawer
      :styles="generateDrawer.styles"
      v-model="generateDrawer.show"
      title="生成点卡"
      width="320"
      :before-close="handleCloseGenerate"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="generateInfo && generateInfoFormReady"
        ref="generateInfoForm"
        :model="generateInfo"
        :rules="generateInfoRules"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="category_id" label="种类">
              <Select
                size="small"
                v-model="generateInfo.category_id"
                clearable
                placeholder="请选择种类"
              >
                <Option
                  v-for="item in categoryList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="count" label="数量">
              <Input
                size="small"
                v-model="generateInfo.count"
                placeholder="请输入数量"
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitGenerate"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  data() {
    return {
      datetimeFormat,
      categoryList: [],

      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },

      generateInfo: null,
      generateInfoRules: {
        // category_id: [
        //   { required: true, message: '请选择分类', trigger: 'blur' },
        // ],
        count: [
          { required: true, message: '请输入生成的数量', trigger: 'blur' },
        ],
      },
      generateInfoFormReady: true,
      generateInfoString: null,

      generateDrawer: {
        show: false,
        styles: {
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static',
        },
      },
    }
  },
  mixins: [dataListMixin],
  computed: {
    categoryMap() {
      let map = {}
      this.categoryList.forEach((row) => {
        map[row.id] = row.name
      })
      return map
    },
  },
  methods: {
    categoryName(id) {
      const map = this.categoryMap
      if (Object.keys(map).length < 1) {
        return '--'
      }
      return map[id]
    },
    // 取分类列表
    handleGetCategoryList() {
      api.vipCardCategory
        .list({
          status: 1,
        })
        .then((data) => {
          if (!data) return
          this.categoryList = data.vip_card_category_list || []
        })
        .catch(() => {})
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      this.handleGetCategoryList()

      // 下面的 params 是获取的表单查询参数
      const {
        category_id,
        status,
        page,
      } = this.$parent.$parent.$refs.filter.params
      if (page) {
        this.page = page
        this.$parent.$parent.$refs.filter.params.page = null
      }
      api.vipCard
        .list({
          category_id,
          status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.vip_card_list || []
          this.dataTotal = data.vip_card_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.vipCard
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.vip_card || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.vipCard
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量置已使用
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.vipCard
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量置禁用
    handleForbiddenMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.vipCard
        .forbidden({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.vipCard
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.vipCard
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 编辑
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        status: 1,
      }
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            category_id: this.dataInfo.category_id,
            status: this.dataInfo.status,
          }
          // console.log(params)
          api.vipCard
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },
    // 生成
    handleOpenGenerate() {
      this.generateInfo = {
        category_id: '',
        count: '',
      }

      this.generateInfoString = this.createDataInfoString(this.generateInfo)
      this.generateDrawer.type = 'new'
      this.generateDrawer.show = true
      this.handleInitGenerateInfoForm()
    },
    handleSubmitGenerate() {
      this.$refs.generateInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            category_id: this.generateInfo.category_id,
            count: this.generateInfo.count,
          }
          // console.log(params)
          api.vipCard
            .generate(params)
            .then(() => {
              this.loading = false

              this.generateDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    // 关闭生成
    handleCloseGenerate() {
      if (this.generateDrawer.type === 'edit') {
        if (
          this.generateInfoString !==
          this.createDataInfoString(this.generateInfo)
        ) {
          // eslint-disable-next-line
          return new Promise((resolve, reject) => {
            this.$Modal.confirm({
              title: '关闭确认',
              content: '您已经修改了信息，是否直接关闭？',
              onOk: () => {
                resolve()
              },
            })
          })
        }
      }
    },
    handleInitGenerateInfoForm() {
      this.generateInfoFormReady = false
      this.$nextTick(() => {
        this.generateInfoFormReady = true
      })
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
