<template>
  <div>
    <ButtonGroup size="small" class="ivu-ml-8">
      <!-- <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        未读
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        已读
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button> -->
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="user">
        {{ row.user_username !== '' ? row.user_username : row.user_email }}
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="未读" />
        <Badge v-else-if="row.status === 0" color="yellow" text="已读" />
      </template>
      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>
      <template slot-scope="{ row }" slot="video_source_quality">
        {{ qualityFormat(row.video_source_quality) }}
      </template>
      <template slot-scope="{ row }" slot="client_type">
        {{ clientFormat(row.client_type) }}
      </template>
      <template slot-scope="{ row }" slot="content" v-if="row.content">
        <Ellipsis :text="row.content" :length="28" :lines="1" tooltip />
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            详情
          </Button>
          <Button type="primary" ghost @click="goplay(row)">打开</Button>
          <!-- <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button> -->
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center video_report_details">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      title="视频反馈详情"
      width="540"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="period" label="片名">
              <span class="red">{{ dataInfo.video_title }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="name" label="用户名">
              <span>{{ dataInfo.user_username || dataInfo.user_email }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="清晰度">
              <span>{{ qualityFormat(dataInfo.video_source_quality) }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="分集">
              <span>{{ dataInfo.symbol }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="内容">
              <span>{{ dataInfo.content }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备注">
              <span>{{ dataInfo.remarks }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="客户端">
              <span>{{ clientFormat(dataInfo.client_type) }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="操作系统">
              <span>{{ dataInfo.os }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="屏幕宽度(单位: px)">
              <span>{{ dataInfo.screen_width }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="屏幕高度(单位: px)">
              <span>{{ dataInfo.screen_height }}</span>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="浏览器">
              <span>{{ dataInfo.browser }}</span>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="反馈时间">
              <span>{{ datetimeFormat(dataInfo.create_time) }}</span>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <!-- <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div> -->
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'
import requestSetting from '@/setting/request'
export default {
  data() {
    return {
      datetimeFormat,
      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },
    }
  },
  mixins: [dataListMixin],
  methods: {
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      const { status, date } = this.$parent.$parent.$refs.filter.params
      api.videoReport
        .list({
          status,
          start_time:
            date[0] !== '' ? this.$Date(date[0]).format('YYYY-MM-DD') : '',
          end_time:
            date[1] !== '' ? this.$Date(date[1]).format('YYYY-MM-DD') : '',
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.video_report_list || []
          this.dataTotal = data.video_report_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.videoReport
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.video_report || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量显示
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.videoReport
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量隐藏
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.videoReport
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.videoReport
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.videoReport
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },

    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },

    //清晰度
    qualityFormat(quality) {
      switch (quality) {
        case 1:
          return '标清'
        case 2:
          return '高清'
        case 3:
          return '超清'
        case 4:
          return '蓝光'
        default:
          break
      }
    },
    //客户端
    clientFormat(client) {
      switch (client) {
        case 1:
          return 'WEB_PC'
        case 2:
          return 'WEB_MOBILE'
        case 3:
          return 'APP'
        default:
          break
      }
    },
    //跳转播放页
    goplay(row) {
      window.open(
        requestSetting.openurl +
          '/play.html?video_id=' +
          row.video_id +
          (row.video_fragment_id ? '&lid=' + row.video_fragment_id : '') +
          (row.video_source_quality
            ? '&quality=' + row.video_source_quality
            : ''),
      )
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
.red {
  color: red;
}
.video_report_details .ivu-form-item-content {
  font-size: 18px;
}
</style>
