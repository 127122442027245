import request from './request'

export default {
  videoPoster(data) {
    return request.post('/upload/videoPoster', data, { normalRequest: true })
  },
  userAvatar(data) {
    return request.post('/upload/userAvatar', data, { normalRequest: true })
  },
  adminAvatar(data) {
    return request.post('/upload/adminAvatar', data, { normalRequest: true })
  },
  adPoster(data) {
    return request.post('/upload/adPoster', data, { normalRequest: true })
  },
  bannerPoster(data) {
    return request.post('/upload/bannerPoster', data, { normalRequest: true })
  },
  image(data) {
    return request.post('/upload/image', data, { normalRequest: true })
  },
  file(data) {
    return request.post('/upload/file', data, { normalRequest: true })
  },
}
