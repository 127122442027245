<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="APP版本" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt" ref="card">
      <o-filter
        ref="filter"
        :platform-list="platformList"
        @on-submit="handleGetData"
      />
      <o-table
        ref="table"
        :platform-list="platformList"
        @on-fullscreen="handleFullscreen"
      />
    </Card>
  </div>
</template>
<script>
import screenfull from 'screenfull'
import oFilter from './filter'
import oTable from './table'

export default {
  name: 'appVersion-list',
  components: {
    oFilter,
    oTable,
  },
  data() {
    return {
      platformList: [
        {
          value: 1,
          label: '安卓',
        },
        {
          value: 2,
          label: 'IOS',
        },
        {
          value: 3,
          label: 'Windows Phone',
        },
        {
          value: 4,
          label: 'H5',
        },
      ],
    }
  },
  methods: {
    handleGetData() {
      this.$refs.table.handleGetDataList()
    },
    handleFullscreen(state) {
      if (state) {
        screenfull.request(this.$refs.card.$el)
      } else {
        screenfull.exit()
      }
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
