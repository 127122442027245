<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="充值账号" prop="username" label-for="username">
          <Input
            size="small"
            v-model="params.username"
            clearable
            placeholder="输入充值账号/被充值账号"
          />
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="订单号" prop="no" label-for="no">
          <Input
            size="small"
            v-model="params.no"
            clearable
            placeholder="订单号"
          />
        </FormItem>
      </Col>

      <Col v-bind="smallGrid">
        <FormItem label="支付方式" prop="payment_method_id">
          <Select
            size="small"
            v-model="params.payment_method_id"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in paymentMethodList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="支付网关" prop="payment_gateway_id">
          <Select
            size="small"
            v-model="params.payment_gateway_id"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in paymentGatewayList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <!-- <Col v-bind="smallGrid">
        <FormItem label="支付渠道" prop="payment_channel_id">
          <Select
            size="small"
            v-model="params.payment_channel_id"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in currentPaymentChannelList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col> -->
      <Col v-bind="smallGrid">
        <FormItem label="状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择"
          >
            <Option value="0">刚下单</Option>
            <Option value="1">待付款</Option>
            <Option value="2">付款成功</Option>
            <Option value="-1">超时未付款</Option>
            <Option value="-2">付款失败</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem
          label="下单时间"
          prop="create_date"
          label-for="create_date"
        >
          <DatePicker
            size="small"
            v-model="params.create_date"
            clearable
            type="daterange"
            :options="dateOptions"
            placeholder="请选择下单时间"
            v-width="'100%'"
          />
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="支付时间" prop="pay_date" label-for="pay_date">
          <DatePicker
            size="small"
            v-model="params.pay_date"
            clearable
            type="daterange"
            :options="dateOptions"
            placeholder="请选择支付时间"
            v-width="'100%'"
          />
        </FormItem>
      </Col>

      <Col span="4">
        <FormItem
          label="为Ta充值"
          prop="pay_for_other"
          label-for="pay_for_other"
        >
          <Select
            size="small"
            v-model="params.is_pay_for_other"
            clearable
            placeholder="请选择"
          >
            <Option value="1">是</Option>
            <Option value="2">否</Option>
            <Option value="0">全部</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span="5">
        <FormItem label="流水号" prop="pay_sn" label-for="pay_sn">
          <Input
            size="small"
            v-model="params.pay_sn"
            clearable
            placeholder="流水号"
          />
        </FormItem>
      </Col>

      <Col span="3" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
          <!-- <a v-font="14" class="ivu-ml-8" @click="collapse = !collapse">
            <template v-if="!collapse">
              展开
              <Icon type="ios-arrow-down" />
            </template>
            <template v-else>
              收起
              <Icon type="ios-arrow-up" />
            </template>
          </a> -->
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'

export default {
  props: {
    paymentMethodList: {
      type: Array,
      default() {
        return []
      },
    },
    paymentGatewayList: {
      type: Array,
      default() {
        return []
      },
    },
    paymentChannelList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 8,
        sm: 24,
        xs: 24,
      },
      smallGrid: {
        xl: 4,
        lg: 6,
        md: 8,
        sm: 12,
        xs: 24,
      },

      params: {
        no: '',
        username: '',
        payment_method_id: '',
        payment_gateway_id: '',
        payment_channel_id: '',
        status: '',
        pay_sn: '',
        create_date: [],
        pay_date: [],
        is_pay_for_other: '',
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
    currentPaymentChannelList() {
      let list = this.paymentChannelList
      if (this.params.payment_method_id) {
        list = list.filter(
          (item) => item.payment_method_id === this.params.payment_method_id,
        )
        console.log(list)
      }

      if (this.params.payment_gateway_id) {
        list = list.filter(
          (item) => item.payment_gateway_id === this.params.payment_gateway_id,
        )
      }

      return list
    },
  },
  methods: {
    handleSubmit() {
      // console.log(this.params)
      this.params.page = 1
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
  },
}
</script>
