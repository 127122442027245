<template>
  <Dropdown placement="right-start" :class="dropdownClasses">
    <li :class="menuItemClasses" v-if="topLevel">
      <o-menu-side-title :menu="menu" hide-title collapse />
    </li>
    <DropdownItem v-else>
      <o-menu-side-title
        :menu="menu"
        :selected="openNames.indexOf(menu.path) >= 0"
        collapse
      />
      <Icon type="ios-arrow-forward" class="layout-menu-side-arrow" />
    </DropdownItem>
    <DropdownMenu slot="list">
      <div class="layout-menu-side-collapse-title" v-if="showCollapseMenuTitle">
        <o-menu-side-title :menu="menu" collapse />
      </div>
      <template v-for="(item, index) in menu.children">
        <i-link
          :to="item.path"
          :target="item.target"
          v-if="item.children === undefined || !item.children.length"
          :key="index"
          @click.native="handleClick(item.path)"
        >
          <DropdownItem
            :divided="item.divided"
            :class="{
              'layout-menu-side-collapse-item-selected':
                item.path === activePath,
            }"
          >
            <o-menu-side-title :menu="item" collapse />
          </DropdownItem>
        </i-link>
        <o-menu-side-collapse v-else :menu="item" :key="index" />
      </template>
    </DropdownMenu>
  </Dropdown>
</template>

<script>
import oMenuSideTitle from './menu-title'
import clickItem from '../mixins/click-item'
import { mapState } from 'vuex'

export default {
  name: 'oMenuSideCollapse',
  components: { oMenuSideTitle },
  mixins: [clickItem],
  props: {
    menu: {
      type: Object,
      default() {
        return {}
      },
    },
    // 是否是第一级，区分在于左侧和展开侧
    topLevel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('admin/layout', ['siderTheme', 'showCollapseMenuTitle']),
    ...mapState('admin/menu', ['activePath', 'openNames']),
    dropdownClasses() {
      return {
        'layout-menu-side-collapse-top': this.topLevel,
        'layout-menu-side-collapse-dark': this.siderTheme === 'dark',
      }
    },
    menuItemClasses() {
      return [
        'ivu-menu-item layout-menu-side-collapse-top-item',
        {
          'ivu-menu-item-selected ivu-menu-item-active':
            this.openNames.indexOf(this.menu.path) >= 0, // -active 在高亮时，有背景
        },
      ]
    },
  },
}
</script>
