import request from './request'

export default {
  list(data) {
    return request.post('/faqCategory/list', data)
  },
  info(data) {
    return request.post('/faqCategory/info', data)
  },
  save(data) {
    return request.post('/faqCategory/save', data)
  },
  enable(data) {
    return request.post('/faqCategory/enable', data)
  },
  disable(data) {
    return request.post('/faqCategory/disable', data)
  },
  delete(data) {
    return request.post('/faqCategory/delete', data)
  },
}
