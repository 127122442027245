<template>
  <Layout class="layout">
    <Sider
      v-if="!isMobile && !hideSider"
      class="layout-sider"
      :class="siderClasses"
      :width="menuSideWidth"
    >
      <o-menu-side :hide-logo="isHeaderStick && headerFix && showHeader" />
    </Sider>
    <Layout class="layout-inside" :class="insideClasses">
      <transition name="fade-quick">
        <Header
          class="layout-header"
          :class="headerClasses"
          :style="headerStyle"
          v-show="showHeader"
          v-resize="handleHeaderWidthChange"
        >
          <o-header-logo v-if="isMobile && showMobileLogo" />
          <o-header-logo v-if="!isMobile && isHeaderStick && headerFix" />
          <o-header-collapse
            v-if="(isMobile || showSiderCollapse) && !hideSider"
            @on-toggle-drawer="handleToggleDrawer"
          />
          <o-header-reload
            v-if="!isMobile && showReload"
            @on-reload="handleReload"
          />
          <o-menu-head v-if="headerMenu && !isMobile" ref="menuHead" />
          <o-header-breadcrumb
            v-if="showBreadcrumb && !headerMenu && !isMobile"
            ref="breadcrumb"
          />
          <!-- <o-header-search
            v-if="showSearch && !headerMenu && !isMobile && !showBreadcrumb"
          /> -->
          <div class="layout-header-right">
            <!-- <o-header-search
              v-if="
                (showSearch && isMobile) ||
                (showSearch && (headerMenu || showBreadcrumb))
              "
            /> -->
            <o-menu-head v-if="headerMenu && isMobile" />
            <!-- <o-header-log v-if="isDesktop && showLog" /> -->
            <o-header-fullscreen v-if="isDesktop && showFullscreen" />
            <!-- <o-header-notice v-if="showNotice" /> -->
            <o-header-user />
            <!-- <o-header-i18n v-if="showI18n" /> -->
            <o-header-setting v-if="enableSetting && !isMobile" />
          </div>
        </Header>
      </transition>
      <Content class="layout-content" :class="contentClasses">
        <transition name="fade-quick">
          <o-tabs v-if="tabs" v-show="showHeader" @on-reload="handleReload" />
        </transition>
        <div class="layout-content-main">
          <keep-alive :include="keepAlive">
            <router-view v-if="loadRouter" />
          </keep-alive>
        </div>
      </Content>
      <!-- <o-copyright /> -->
    </Layout>
    <div v-if="isMobile && !hideSider">
      <Drawer
        v-model="showDrawer"
        placement="left"
        :closable="false"
        :class-name="drawerClasses"
      >
        <o-menu-side />
      </Drawer>
    </div>
  </Layout>
</template>

<script>
import oMenuHead from './menu-head'
import oMenuSide from './menu-side'
import oHeaderLogo from './header-logo'
import oHeaderCollapse from './header-collapse'
import oHeaderReload from './header-reload'
import oHeaderBreadcrumb from './header-breadcrumb'
// import oHeaderSearch from './header-search'
// import oHeaderLog from './header-log'
import oHeaderFullscreen from './header-fullscreen'
// import oHeaderNotice from './header-notice'
import oHeaderUser from './header-user'
// import oHeaderI18n from './header-i18n'
import oHeaderSetting from './header-setting'
import oTabs from './tabs'
// import oCopyright from '@/components/copyright'

import { mapState, mapGetters, mapMutations } from 'vuex'
import setting from '@/setting'
import { requestAnimation } from '@/utils'

export default {
  name: 'LayoutBasic',
  components: {
    oMenuHead,
    oMenuSide,
    oHeaderLogo,
    oHeaderCollapse,
    oHeaderReload,
    oHeaderBreadcrumb,
    // oHeaderSearch,
    // oHeaderLog,
    oHeaderFullscreen,
    // oHeaderNotice,
    oHeaderUser,
    // oHeaderI18n,
    oHeaderSetting,
    oTabs,
    // oCopyright,
  },
  data() {
    return {
      showDrawer: false,
      ticking: false,
      headerVisible: true,
      oldScrollTop: 0,
      isDelayHideSider: false, // hack，当从隐藏侧边栏的 header 切换到正常 header 时，防止 Logo 抖动
      loadRouter: true,
    }
  },
  computed: {
    ...mapState('admin/layout', [
      'siderTheme',
      'headerTheme',
      'headerStick',
      'tabs',
      'tabsFix',
      'siderFix',
      'headerFix',
      'headerHide',
      'headerMenu',
      'isMobile',
      'isTablet',
      'isDesktop',
      'menuCollapse',
      'showMobileLogo',
      'showSearch',
      'showNotice',
      'showFullscreen',
      'showSiderCollapse',
      'showBreadcrumb',
      'showLog',
      'showI18n',
      'showReload',
      'enableSetting',
    ]),
    ...mapState('admin/view', ['keepAlive']),
    ...mapGetters('admin/menu', ['hideSider']),
    // 如果开启 headerMenu，且当前 header 的 hideSider 为 true，则将顶部按 headerStick 处理
    // 这时，即使没有开启 headerStick，仍然按开启处理
    isHeaderStick() {
      let state = this.headerStick
      if (this.hideSider) state = true
      return state
    },
    showHeader() {
      let visible = true
      if (this.headerFix && this.headerHide && !this.headerVisible)
        visible = false
      return visible
    },
    headerClasses() {
      return [
        `layout-header-color-${this.headerTheme}`,
        {
          'layout-header-fix': this.headerFix,
          'layout-header-fix-collapse': this.headerFix && this.menuCollapse,
          'layout-header-mobile': this.isMobile,
          'layout-header-stick': this.isHeaderStick && !this.isMobile,
          'layout-header-with-menu': this.headerMenu,
          'layout-header-with-hide-sider':
            this.hideSider || this.isDelayHideSider,
        },
      ]
    },
    headerStyle() {
      const menuWidth = this.isHeaderStick
        ? 0
        : this.menuCollapse
        ? 80
        : setting.menuSideWidth
      return this.isMobile || !this.headerFix
        ? {}
        : {
            width: `calc(100% - ${menuWidth}px)`,
          }
    },
    siderClasses() {
      return {
        'layout-sider-fix': this.siderFix,
        'layout-sider-dark': this.siderTheme === 'dark',
      }
    },
    contentClasses() {
      return {
        'layout-content-fix-with-header': this.headerFix,
        'layout-content-with-tabs': this.tabs,
        'layout-content-with-tabs-fix': this.tabs && this.tabsFix,
      }
    },
    insideClasses() {
      return {
        'layout-inside-fix-with-sider': this.siderFix,
        'layout-inside-fix-with-sider-collapse':
          this.siderFix && this.menuCollapse,
        'layout-inside-with-hide-sider': this.hideSider,
        'layout-inside-mobile': this.isMobile,
      }
    },
    drawerClasses() {
      let className = 'layout-drawer'
      if (this.siderTheme === 'dark') className += ' layout-drawer-dark'
      return className
    },
    menuSideWidth() {
      return this.menuCollapse ? 80 : setting.menuSideWidth
    },
  },
  watch: {
    hideSider() {
      this.isDelayHideSider = true
      setTimeout(() => {
        this.isDelayHideSider = false
      }, 0)
    },
    $route(to, from) {
      if (to.name === from.name) {
        // 相同路由，不同参数，跳转时，重载页面
        if (setting.sameRouteForceUpdate) {
          this.handleReload()
        }
      }
    },
  },
  methods: {
    ...mapMutations('admin/layout', ['setMenuCollapse']),
    ...mapMutations('admin/view', ['keepAlivePush', 'keepAliveRemove']),
    handleToggleDrawer(state) {
      if (typeof state === 'boolean') {
        this.showDrawer = state
      } else {
        this.showDrawer = !this.showDrawer
      }
    },
    handleScroll() {
      if (!this.headerHide) return

      const scrollTop =
        document.body.scrollTop + document.documentElement.scrollTop

      if (!this.ticking) {
        this.ticking = true
        requestAnimation(() => {
          if (this.oldScrollTop > scrollTop) {
            this.headerVisible = true
          } else if (scrollTop > 300 && this.headerVisible) {
            this.headerVisible = false
          } else if (scrollTop < 300 && !this.headerVisible) {
            this.headerVisible = true
          }
          this.oldScrollTop = scrollTop
          this.ticking = false
        })
      }
    },
    handleHeaderWidthChange() {
      const $breadcrumb = this.$refs.breadcrumb
      if ($breadcrumb) {
        $breadcrumb.handleGetWidth()
        $breadcrumb.handleCheckWidth()
      }
      const $menuHead = this.$refs.menuHead
      if ($menuHead) {
        // todo $menuHead.handleGetMenuHeight()
      }
    },
    handleReload() {
      // 针对缓存的页面也生效
      const isCurrentPageCache = this.keepAlive.indexOf(this.$route.name) > -1
      const pageName = this.$route.name
      if (isCurrentPageCache) {
        this.keepAliveRemove(pageName)
      }
      this.loadRouter = false
      this.$nextTick(() => {
        this.loadRouter = true
        if (isCurrentPageCache) {
          this.keepAlivePush(pageName)
        }
      })
    },
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, { passive: true })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  },
  created() {
    if (this.isTablet && this.showSiderCollapse) this.setMenuCollapse(true)
  },
}
</script>
