/**
 * 包装于 lowdb 之上的数据存储
 */

import { read, write } from './_db'

import user from './user'
import token from './token'
import access from './access'
// import i18n from './i18n'
import logs from './logs'
import views from './views'
import setting from './setting'

export default {
  set: write,
  get: read,
  clean() {
    user.clean()
    token.clean()
  },
  user,
  token,
  access,
  // i18n,
  logs,
  views,
  setting,
}
