import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

const pre = 'statistics-'

export default {
  path: '/statistics',
  name: 'statistics',
  redirect: {
    name: `${pre}daily`,
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'daily',
      name: `${pre}daily`,
      meta: {
        ...meta,
        title: '每日数据',
      },
      component: () => import('@/views/statistics/daily'),
    },
  ],
}
