import request from './request'

export default {
  list(data) {
    return request.post('/video/list', data)
  },
  info(data) {
    return request.post('/video/info', data)
  },
  save(data) {
    return request.post('/video/save', data)
  },
  enable(data) {
    return request.post('/video/enable', data)
  },
  disable(data) {
    return request.post('/video/disable', data)
  },
  delete(data) {
    return request.post('/video/delete', data)
  },
  enableTop(data) {
    return request.post('/video/enabletop', data)
  },
  disableTop(data) {
    return request.post('/video/disabletop', data)
  },
  enableRank(data) {
    return request.post('/video/enablerank', data)
  },
  disableRank(data) {
    return request.post('/video/disablerank', data)
  },

  category(data) {
    return request.post('/video/category', data)
  },
  server(data) {
    return request.post('/video/server', data)
  },
}
