<template>
  <div>
    <ButtonGroup size="small">
      <!-- <Button type="primary" disabled>导出</Button> -->
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="user">
        <!-- <Avatar
          shape="square"
          :src="row.user_avatar"
          v-if="row.user_avatar"
        /> -->
        <!-- <Avatar shape="square" icon="ios-person" v-else /> -->

        <Tooltip placement="top" v-if="row.pay_for_other">
          <Icon type="md-contacts" />
          <div slot="content">
            <p>为Ta人充值的订单：</p>
            <p>{{ row.pay_for_other }}</p>
          </div>
        </Tooltip>
        {{ row.user_username !== '' ? row.user_username : row.user_email }}
      </template>

      <template slot-scope="{ row }" slot="payment_method">
        <Tag :color="paymentMethodColor(row.payment_method_id)">
          {{ paymentMethodName(row.payment_method_id) }}
        </Tag>
      </template>

      <template slot-scope="{ row }" slot="payment_gateway">
        {{ paymentGatewayName(row.payment_gateway_id) }}
      </template>

      <template slot-scope="{ row }" slot="payment_channel">
        {{ paymentChannelName(row.payment_channel_id) }}
      </template>

      <template slot-scope="{ row }" slot="amount">
        {{ row.amount }} {{ row.currency }}
      </template>

      <template slot-scope="{ row }" slot="platform">
        <span class="payment_platform" v-if="row.platform === 1">
          <Icon size="20" type="ios-desktop" />
          PC
        </span>
        <span class="payment_platform" v-if="row.platform === 2">
          <Icon size="20" type="md-phone-portrait" />
          移动端
        </span>
        <span class="payment_platform" v-if="row.platform === 3">
          <Icon size="20" type="logo-android" />
          Android
        </span>
        <span class="payment_platform" v-if="row.platform === 4">
          <Icon size="20" type="logo-apple" />
          iOS
        </span>
        <!-- <Badge v-if="row.platform === 1" color="blue" text="PC" />
        <Badge v-else-if="row.platform === 2" color="blue" text="移动" />
        <Badge v-else-if="row.platform === -1" color="green" text="Android" />
        <Badge v-else-if="row.platform === -2" color="green" text="iOS" /> -->
      </template>

      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 0" color="yellow" text="刚下单" />
        <Badge v-else-if="row.status === 1" color="blue" text="待付款" />
        <Badge v-else-if="row.status === 2" color="green" text="已付款" />
        <Badge v-else-if="row.status === -1" color="yellow" text="超时" />
        <Badge v-else-if="row.status === -2" color="red" text="付款失败" />
      </template>

      <template slot-scope="{ row }" slot="payer">
        {{ row.payer }}
      </template>

      <template slot-scope="{ row }" slot="pay_sn">
        {{ row.pay_sn }}
      </template>

      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>

      <template slot-scope="{ row }" slot="pay_time">
        {{ datetimeFormat(row.pay_time) }}
      </template>

      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button>
          <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button>
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑支付订单'
          : dataDrawer.type === 'new'
          ? '添加支付订单'
          : '支付订单信息'
      "
      width="768"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="title" label="标题">
              <Input
                size="small"
                v-model="dataInfo.title"
                placeholder="请输入标题"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="content" label="内容">
              <Input
                type="textarea"
                size="small"
                :rows="10"
                v-model="dataInfo.content"
                placeholder="请输入内容"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="sort" label="排序">
              <Input
                size="small"
                v-model="dataInfo.sort"
                placeholder="-99 ~ 99"
                maxlength="3"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="status" label="状态">
              <RadioGroup size="small" v-model="dataInfo.status">
                <Radio :label="1"><span>正常</span></Radio>
                <Radio :label="0"><span>禁用</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  props: {
    paymentMethodList: {
      type: Array,
      default() {
        return []
      },
    },
    paymentGatewayList: {
      type: Array,
      default() {
        return []
      },
    },
    paymentChannelList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      datetimeFormat,
      categoryList: [],

      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },
    }
  },
  mixins: [dataListMixin],
  methods: {
    paymentMethodName(id) {
      const index = this.paymentMethodList.findIndex((item) => item.id === id)
      if (index === false) {
        return ''
      }
      return this.paymentMethodList[index].name
    },
    paymentGatewayName(id) {
      const index = this.paymentGatewayList.findIndex((item) => item.id === id)
      if (index === false) {
        return ''
      }
      return this.paymentGatewayList[index].name
    },
    paymentChannelName(id) {
      const index = this.paymentChannelList.findIndex((item) => item.id === id)
      if (index === false) {
        return ''
      }
      return this.paymentChannelList[index].name
    },
    paymentMethodColor(id) {
      const index = this.paymentMethodList.findIndex((item) => item.id === id)
      if (index === false) {
        return null
      }
      const symbol = this.paymentMethodList[index].symbol
      if (symbol === 'paypal') {
        return '#003188'
      } else if (symbol === 'alipay') {
        return '#56abe4'
      } else if (symbol === 'wechat') {
        return '#00d30c'
      }
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      const {
        no,
        username,
        payment_method_id,
        payment_gateway_id,
        payment_channel_id,
        is_pay_for_other,
        status,
        create_date,
        pay_date,
        pay_sn,
        page,
      } = this.$parent.$parent.$refs.filter.params
      if (page) {
        this.page = page
        this.$parent.$parent.$refs.filter.params.page = null
      }
      api.paymentOrder
        .list({
          no,
          username,
          payment_method_id,
          payment_gateway_id,
          payment_channel_id,
          is_pay_for_other,
          status,
          create_start_time:
            create_date[0] !== ''
              ? this.$Date(create_date[0]).format('YYYY-MM-DD')
              : '',
          create_end_time:
            create_date[1] !== ''
              ? this.$Date(create_date[1]).format('YYYY-MM-DD')
              : '',
          pay_start_time:
            pay_date[0] !== ''
              ? this.$Date(pay_date[0]).format('YYYY-MM-DD')
              : '',
          pay_end_time:
            pay_date[1] !== ''
              ? this.$Date(pay_date[1]).format('YYYY-MM-DD')
              : '',
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
          pay_sn,
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.payment_order_list || []
          this.dataTotal = data.payment_order_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.paymentOrder
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.paymentOrder || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.paymentOrder
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量禁用
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.paymentOrder
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.paymentOrder
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.paymentOrder
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 编辑
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        title: '',
        content: '',
        sort: 50,
        status: 1,
      }
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            title: this.dataInfo.title,
            content: this.dataInfo.content,
            sort: this.dataInfo.sort,
            status: this.dataInfo.status,
          }
          // console.log(params)
          api.paymentOrder
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
.ivu-table-wrapper {
  overflow: unset !important;
}
.payment_platform {
  display: flex;
  align-items: center;
  i {
    margin-right: 6px;
  }
}
</style>
