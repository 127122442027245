export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '片名',
    key: 'video_title',
    show: true,
    minWidth: 100,
  },
  {
    title: '栏目',
    key: 'parent_category_name',
    show: true,
    width: 95,
  },
  {
    title: '分集',
    key: 'symbol',
    show: true,
    width: 80,
  },
  {
    title: '内容',
    key: 'content',
    show: true,
    slot: 'content',
  },
  {
    title: '清晰度',
    key: 'video_source_quality',
    slot: 'video_source_quality',
    show: true,
    width: 80,
  },
  {
    title: '用户',
    key: 'user',
    slot: 'user',
    show: true,
  },
  {
    title: '客户端',
    key: 'client_type',
    slot: 'client_type',
    show: true,
    width: 130,
  },
  // {
  //   title: '操作系统',
  //   key: 'os',
  //   show: true,
  // },
  // {
  //   title: '屏幕宽度',
  //   key: 'screen_width',
  //   show: false,
  // },
  // {
  //   title: '屏幕高度',
  //   key: 'screen_height',
  //   show: false,
  // },
  // {
  //   title: '备注',
  //   key: 'remarks',
  //   slot: 'remarks',
  //   show: true,
  // },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
    width: 80,
  },
  {
    title: '反馈时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },

  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 160,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
