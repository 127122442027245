/**
 * 插件
 * */

// 错误捕获
// import error from './error'
// 日志插件
// import log from './log'
// 鉴权指令
import auth from '@/plugins/auth'

export default {
  // eslint-disable-next-line
  async install(Vue, options) {
    // 插件
    // Vue.use(error)
    // Vue.use(log)

    // 指令
    Vue.directive('auth', auth)
  },
}
