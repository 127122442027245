export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '名称',
    key: 'name',
    minWidth: 140,
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    minWidth: 100,
    show: true,
  },
  {
    title: '创建时间',
    key: 'create_time',
    slot: 'create_time',
    minWidth: 200,
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    minWidth: 120,
    maxWidth: 160,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
