<template>
  <Submenu :name="menu.path">
    <template slot="title">
      <o-menu-side-title :menu="menu" />
      <Badge
        class="layout-menu-side-badge"
        v-if="badgeData"
        v-bind="badgeData"
      />
    </template>
    <template v-for="(item, index) in menu.children">
      <o-menu-side-item
        v-if="item.children === undefined || !item.children.length"
        :menu="item"
        :key="index"
      />
      <o-menu-side-submenu v-else :menu="item" :key="index" />
    </template>
  </Submenu>
</template>

<script>
import oMenuSideItem from './menu-item'
import oMenuSideTitle from './menu-title'
import menuBadge from '../mixins/menu-badge'

export default {
  name: 'oMenuSideSubmenu',
  components: { oMenuSideItem, oMenuSideTitle },
  mixins: [menuBadge],
  props: {
    menu: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
