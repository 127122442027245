import request from './request'

export default {
  list(data) {
    return request.post('/videoCategory/list', data)
  },
  info(data) {
    return request.post('/videoCategory/info', data)
  },
  save(data) {
    return request.post('/videoCategory/save', data)
  },
  enable(data) {
    return request.post('/videoCategory/enable', data)
  },
  disable(data) {
    return request.post('/videoCategory/disable', data)
  },
  delete(data) {
    return request.post('/videoCategory/delete', data)
  },
}
