import { read, write, unset, exist, push, remove } from './_db'

const root = 'home'
const key = 'views.opened'

export default {
  set(items) {
    return write(root, key, items)
  },
  get() {
    return Object.assign([], read(root, key))
  },
  push(item) {
    if (!exist(root, key)) {
      this.set([])
    }
    return push(root, key, item)
  },
  remove(i) {
    if (!exist(root, key)) {
      return
    }
    return remove(root, key, i)
  },
  clean() {
    return unset(root, key)
  },
}
