import request from './request'

export default {
  signup(data) {
    return request.post('/passport/signup', data)
  },

  signin(data) {
    return request.post('/passport/signin', data)
  },
}
