<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="关键词" prop="keyword" label-for="keyword">
          <Input
            size="small"
            v-model="params.keyword"
            clearable
            placeholder="名称|别名|导演|演员"
          />
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem
          label="栏目"
          prop="parent_category_id"
          label-for="parent_category_id"
        >
          <Select
            size="small"
            v-model="params.parent_category_id"
            clearable
            placeholder="请选择"
            @on-change="handleParentCategoryChange"
          >
            <Option
              v-for="(item, index) in topCategoryList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="类型" prop="category_id" label-for="category_id">
          <Select
            size="small"
            v-model="params.category_id"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in subCategoryList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="年份" prop="year" label-for="year">
          <Select
            size="small"
            v-model="params.year"
            clearable
            placeholder="请选择"
          >
            <Option v-for="(item, index) in years" :value="item" :key="index">
              {{ item }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="地区" prop="region" label-for="region">
          <Select
            size="small"
            v-model="params.region"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in regions"
              :value="item"
              :key="index"
            >
              {{ item }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="语言" prop="language" label-for="language">
          <Select
            size="small"
            v-model="params.language"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in languages"
              :value="item"
              :key="index"
            >
              {{ item }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="级别" prop="paid">
          <Select
            size="small"
            v-model="params.paid"
            clearable
            placeholder="请选择"
          >
            <Option value="0">免费</Option>
            <Option value="1">付费</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="更新状态" prop="state">
          <Select
            size="small"
            v-model="params.state"
            clearable
            placeholder="请选择"
          >
            <Option value="更新中">更新中</Option>
            <Option value="已完结">已完结</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="性质" prop="kind">
          <Select
            size="small"
            v-model="params.kind"
            clearable
            placeholder="请选择"
          >
            <Option value="1">预告片</Option>
            <Option value="2">正片</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="添加时间" prop="date" label-for="date">
          <DatePicker
            size="small"
            v-model="params.date"
            clearable
            type="daterange"
            :options="dateOptions"
            placeholder="请选择"
            v-width="'100%'"
          />
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="显示状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择"
          >
            <Option value="1">显示</Option>
            <Option value="0">隐藏</Option>
          </Select>
        </FormItem>
      </Col>

      <Col v-bind="smallGrid" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
          <!-- <a v-font="14" class="ivu-ml-8" @click="collapse = !collapse">
            <template v-if="!collapse">
              展开
              <Icon type="ios-arrow-down" />
            </template>
            <template v-else>
              收起
              <Icon type="ios-arrow-up" />
            </template>
          </a> -->
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'

export default {
  props: {
    categoryList: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 8,
        sm: 24,
        xs: 24,
      },
      smallGrid: {
        xl: 3,
        lg: 4,
        md: 6,
        sm: 8,
        xs: 12,
      },

      currentSelectedParentCategoryId: null,

      params: {
        keyword: '',
        parent_category_id: '',
        category_id: '',
        year: '',
        region: '',
        language: '',
        paid: '',
        state: '',
        kind: '',
        date: [],
        status: '',
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
    topCategoryList() {
      return this.categoryList.filter((item) => item.pid === 0) || []
    },
    subCategoryList() {
      return this.categoryList.filter(
        (item) => item.pid === this.params.parent_category_id,
      )
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
    handleParentCategoryChange() {
      this.params.category_id = ''
    },
  },
}
</script>
