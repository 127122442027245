import request from './request'

export default {
  list(data) {
    return request.post('/vipCard/list', data)
  },
  info(data) {
    return request.post('/vipCard/info', data)
  },
  save(data) {
    return request.post('/vipCard/save', data)
  },
  enable(data) {
    return request.post('/vipCard/enable', data)
  },
  disable(data) {
    return request.post('/vipCard/disable', data)
  },
  forbidden(data) {
    return request.post('/vipCard/forbidden', data)
  },
  delete(data) {
    return request.post('/vipCard/delete', data)
  },
  generate(data) {
    return request.post('/vipCard/generate', data)
  },
  export(data) {
    return request.post('/vipCard/export', data)
  },

  category(data) {
    return request.post('/vipCard/category', data)
  },
}
