<template>
  <div>
    <ButtonGroup size="small">
      <!-- <Button type="primary" @click="handleOpenCreate">新增</Button> -->
    </ButtonGroup>
    <!-- <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        显示
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        隐藏
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button>
    </ButtonGroup> -->

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="显示" />
        <Badge v-else-if="row.status === 0" color="yellow" text="隐藏" />
      </template>
      <template slot-scope="{ row }" slot="create_time">
        {{ datetimeFormat(row.create_time) }}
      </template>

      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button>
          <Button
            type="success"
            ghost
            @click="openFragment({ id: row.id, title: row.title })"
          >
            分集
          </Button>
          <!-- <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button> -->
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑视频'
          : dataDrawer.type === 'new'
          ? '添加视频'
          : '视频信息'
      "
      width="1024"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        :label-width="70"
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="16">
            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="title" label="名称">
                  <Input
                    size="small"
                    v-model="dataInfo.title"
                    placeholder="请输入名称"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="title_en" label="英文名">
                  <Input
                    size="small"
                    v-model="dataInfo.title_en"
                    placeholder="请输入英文名"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="title_alias" label="别名">
                  <Input
                    size="small"
                    v-model="dataInfo.title_alias"
                    placeholder="请输入别名"
                  />
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="12">
                <FormItem prop="parent_category_id" label="栏目">
                  <Select
                    size="small"
                    v-model="dataInfo.parent_category_id"
                    clearable
                    placeholder="请选择"
                    @on-change="handleParentCategoryChange"
                  >
                    <Option
                      v-for="(item, index) in topCategoryList"
                      :value="item.id"
                      :key="index"
                    >
                      {{ item.name }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem prop="category_id" label="类型">
                  <Select
                    size="small"
                    v-model="dataInfo.category_id"
                    clearable
                    placeholder="请选择"
                  >
                    <Option
                      v-for="(item, index) in subCategoryList"
                      :value="item.id"
                      :key="index"
                    >
                      {{ item.name }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="8">
                <FormItem prop="region" label="地区">
                  <Select
                    size="small"
                    v-model="dataInfo.region"
                    clearable
                    placeholder="请选择"
                  >
                    <Option
                      v-for="(item, index) in regions"
                      :value="item"
                      :key="index"
                    >
                      {{ item }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop="year" label="年份">
                  <Select
                    size="small"
                    v-model="dataInfo.year"
                    clearable
                    placeholder="请选择"
                  >
                    <Option
                      v-for="(item, index) in years"
                      :value="item"
                      :key="index"
                    >
                      {{ item }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop="language" label="语言">
                  <Select
                    size="small"
                    v-model="dataInfo.language"
                    clearable
                    placeholder="请选择"
                  >
                    <Option
                      v-for="(item, index) in languages"
                      :value="item"
                      :key="index"
                    >
                      {{ item }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="8">
                <FormItem prop="paid" label="是否付费">
                  <Select
                    size="small"
                    v-model="dataInfo.paid"
                    clearable
                    placeholder="请选择"
                  >
                    <Option :value="1">付费</Option>
                    <Option :value="0">免费</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop="state" label="更新状态">
                  <Select
                    size="small"
                    v-model="dataInfo.state"
                    clearable
                    placeholder="请选择"
                  >
                    <Option
                      v-for="(item, index) in states"
                      :value="item"
                      :key="index"
                    >
                      {{ item }}
                    </Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop="kind" label="性质">
                  <Select
                    size="small"
                    v-model="dataInfo.kind"
                    clearable
                    placeholder="请选择"
                  >
                    <Option :value="1">预告片</Option>
                    <Option :value="2">正片</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="director" label="导演">
                  <Input
                    size="small"
                    v-model="dataInfo.director"
                    placeholder="请输入导演"
                  />
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="starring" label="主演">
                  <Input
                    size="small"
                    v-model="dataInfo.starring"
                    placeholder="请输入主演"
                  />
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="description" label="简介">
                  <Input
                    type="textarea"
                    :rows="3"
                    size="small"
                    v-model="dataInfo.description"
                    placeholder="请输入简介"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col span="24">
                <FormItem prop="remark" label="备注">
                  <Input
                    size="small"
                    v-model="dataInfo.remark"
                    placeholder="请输入备注"
                  />
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="12">
                <FormItem prop="mosaic" label="马赛克">
                  <RadioGroup size="small" v-model="dataInfo.mosaic">
                    <Radio :label="0" class="ivu-mr">无码</Radio>
                    <Radio :label="1" class="ivu-mr">有码</Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem prop="multiple_fragment" label="是否多集">
                  <RadioGroup
                    size="small"
                    v-model="dataInfo.multiple_fragment"
                  >
                    <Radio :label="0" class="ivu-mr">单集</Radio>
                    <Radio :label="1" class="ivu-mr">多集</Radio>
                  </RadioGroup>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem prop="tag" label="标签">
                  <Input
                    size="small"
                    v-model="dataInfo.tag"
                    placeholder="请输入标签"
                  />
                </FormItem>
              </Col>
            </Row>

            <Row :gutter="16">
              <Col span="6">
                <FormItem prop="score" label="评分">
                  <InputNumber
                    size="small"
                    v-model="dataInfo.score"
                    placeholder="评分"
                    :max="10.0"
                    :min="0"
                    :precision="1"
                    :step="0.1"
                  />
                </FormItem>
              </Col>

              <Col span="6">
                <FormItem prop="status" label="是否显示" :label-width="80">
                  <i-switch
                    size="small"
                    v-model="dataInfo.status"
                    :true-value="1"
                    :false-value="0"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col span="8">
            <Row :getter="16">
              <Col>
                <div class="video-pic">
                  <img
                    v-if="dataInfo.pic !== ''"
                    :src="dataInfo.pic"
                    width="216"
                    height="309"
                    alt=""
                  />
                </div>

                <Upload
                  size="small"
                  :before-upload="handleUploadImage"
                  action=""
                >
                  <Button icon="ios-cloud-upload-outline">选择图片</Button>
                </Upload>
                <div v-if="file !== null">
                  已选图片:
                  <Tag>{{ file.name }}</Tag>
                  <Button
                    size="small"
                    type="primary"
                    ghost
                    @click="uploadImage"
                    :loading="uploading"
                  >
                    {{ uploading ? '上传中' : '点击上传' }}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <!-- <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button> -->
      </div>
    </Drawer>

    <Modal
      v-model="showFragmentModal"
      :mask-closable="false"
      fullscreen
      footer-hide
    >
      <o-fragment ref="fragment" :video-info="currentVideoInfo" />
    </Modal>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'

import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'
import oFragment from './fragment'

export default {
  components: {
    oFragment,
  },
  props: {
    categoryList: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      datetimeFormat,
      file: null,
      uploading: false,

      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },

      showFragmentModal: false,
      currentVideoInfo: {
        id: 0,
        title: '',
      },
    }
  },
  mixins: [dataListMixin],
  computed: {
    topCategoryList() {
      return this.categoryList.filter((item) => item.pid === 0)
    },
    subCategoryList() {
      return this.categoryList.filter(
        (item) => item.pid === this.dataInfo.parent_category_id,
      )
    },
  },
  mounted() {
    // var xmlhttp = new XMLHttpRequest()
    // // get方法带参数是将参数写在url里面传过去给后端
    // xmlhttp.open('GET', 'https://movie.querydata.org/api?id=1292052', true)
    // // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
    // // xmlhttp.setRequestHeader("dingyi", "header-dingyi-value");
    // xmlhttp.send()
    // // readyState == 4 为请求完成， status == 200 为请求成功返回的状态
    // xmlhttp.onreadystatechange = function () {
    //   if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
    //     console.log(JSON.parse(xmlhttp.responseText))
    //   }
    // }
    // var xmlhttp2 = new XMLHttpRequest()
    // // get方法带参数是将参数写在url里面传过去给后端
    // xmlhttp2.open(
    //   'GET',
    //   'https://movie.querydata.org/api/generateimage?id=1292052&lang=Cn',
    //   true,
    // )
    // // xmlhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
    // // xmlhttp.setRequestHeader("dingyi", "header-dingyi-value");
    // xmlhttp2.send()
    // // readyState == 4 为请求完成， status == 200 为请求成功返回的状态
    // xmlhttp2.onreadystatechange = function () {
    //   if (xmlhttp2.readyState == 4 && xmlhttp2.status == 200) {
    //     console.log(xmlhttp2.responseText)
    //   }
    // }
  },
  methods: {
    timestamp() {
      const t = parseInt(new Date().getTime() / 1000)
      return t
    },

    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      const {
        keyword,
        parent_category_id,
        category_id,
        year,
        region,
        language,
        paid,
        state,
        kind,
        date,
        top,
        rank,
        status,
      } = this.$parent.$parent.$refs.filter.params
      api.video
        .list({
          keyword,
          parent_category_id,
          category_id,
          year,
          region,
          language,
          paid,
          state,
          kind,
          start_time:
            date[0] !== '' ? this.$Date(date[0]).format('YYYY-MM-DD') : '',
          end_time:
            date[1] !== '' ? this.$Date(date[1]).format('YYYY-MM-DD') : '',
          top,
          rank,
          status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.video_list || []
          this.dataTotal = data.video_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.video
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.video || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量显示
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.video
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量隐藏
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.video
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.video
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.video
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 批量设置首页优先
    handleEnableTopMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.video
        .enableTop({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量取消首页优先
    handleDisableTopMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.video
        .disableTop({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量设置排行优先
    handleEnableRankMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.video
        .enableRank({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量取消排行优先
    handleDisableRankMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.video
        .disableRank({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 添加
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        title: '',
        title_en: '',
        title_alias: '',
        parent_category_id: '',
        category_id: '',
        region: '',
        year: '',
        language: '',
        paid: 0,
        state: '',
        kind: '',
        director: '',
        starring: '',
        description: '',
        remark: '',
        mosaic: 0,
        multiple_fragment: 1,
        tag: '',

        score: 0,
        hits: '',
        hits_extra: '',

        top: 0,
        rank: 0,
        status: 1,

        pic: '',
      }

      this.dataInfoString = this.createDataInfoString(this.dataInfo)
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            title: this.dataInfo.title,
            title_en: this.dataInfo.title_en,
            title_alias: this.dataInfo.title_alias,
            category_id: this.dataInfo.category_id,
            region: this.dataInfo.region,
            year: this.dataInfo.year,
            language: this.dataInfo.language,
            paid: this.dataInfo.paid,
            state: this.dataInfo.state,
            kind: this.dataInfo.kind,
            director: this.dataInfo.director,
            starring: this.dataInfo.starring,
            description: this.dataInfo.description,
            remark: this.dataInfo.remark,
            mosaic: this.dataInfo.mosaic,
            multiple_fragment: this.dataInfo.multiple_fragment,
            tag: this.dataInfo.tag,

            score: this.dataInfo.score,
            hits: this.dataInfo.hits,
            hits_extra: this.dataInfo.hits_extra,
            top: this.dataInfo.top,
            rank: this.dataInfo.rank,
            status: this.dataInfo.status,

            pic: this.dataInfo.pic,
          }
          // console.log(params)
          api.video
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },

    // 改变顶级分类
    handleParentCategoryChange() {
      this.dataInfo.category_id = ''
    },

    // 上传前拦截进行手动上传
    handleUploadImage(file) {
      // if (file.size > 40960000) {
      //   return false
      // }

      let params = new FormData()
      params.append('image', file)
      // params.append('width', 72)
      // params.append('height', 72)

      api.upload.videoPoster(params).then((data) => {
        this.dataInfo.pic = data.url
      })

      return false
    },

    openFragment({ id, title }) {
      this.currentVideoInfo = { id, title }
      this.showFragmentModal = true
      this.$nextTick(() => {
        this.$refs.fragment.handleGetData()
      })
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
.ivu-modal-body {
  overflow-x: hidden !important;
}
.video-pic {
  margin-bottom: 15px;
  padding: 10px;
  width: 236px;
  height: 329px;
  border: 1px solid #eee;
  border-radius: 3px;

  > img {
    cursor: pointer;
  }
}
</style>
