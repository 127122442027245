export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '用户',
    key: 'uid',
    slot: 'user',
    width: 280,
    show: true,
  },
  {
    title: '分类',
    key: 'category_id',
    slot: 'category',
    width: 140,
    show: true,
  },
  {
    title: '内容',
    key: 'content',
    slot: 'content',
    minWidth: 100,
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    width: 100,
    show: true,
  },
  {
    title: '时间',
    key: 'create_time',
    slot: 'create_time',
    width: 200,
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
