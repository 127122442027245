import system from './system'
import str from './str'
import dict from './dict'
import log from './log'
import storage from './storage'

import setting from '@/setting'

const utils = {
  system,
  str,
  dict,
  log,
  storage,
}

function tTitle(title = '') {
  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1])
    } else {
      return title
    }
  } else {
    return title
  }
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
utils.title = function ({ title, count }) {
  title = tTitle(title)
  let fullTitle = title
    ? `${title} - ${setting.titleSuffix}`
    : setting.titleSuffix

  if (count) fullTitle = `(${count}条消息)${fullTitle}`
  window.document.title = fullTitle
}

function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task)
  }

  setTimeout(task, 16)
}

export { requestAnimation }

export default utils
