import request from './request'

export default {
  info(data) {
    return request.post('/config/info', data)
  },
  saveBasic(data) {
    return request.post('/config/saveBasic', data)
  },
  saveVideo(data) {
    return request.post('/config/saveVideo', data)
  },
}
