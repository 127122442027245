import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export function datetimeFormat(timestamp) {
  if (timestamp < 1) return '--'
  timestamp *= 1000
  dayjs.extend(utc)
  dayjs.extend(timezone)

  return dayjs(timestamp).tz('America/New_York').format('YYYY-MM-DD HH:mm')
}
