// import form from './admin/modules/form'
// import list from './admin/modules/list'
// import search from './admin/modules/search'
// import profile from './admin/modules/profile'
// import result from './admin/modules/result'
// import exception from './admin/modules/exception'
// import setting from './admin/modules/setting'
// import editor from './admin/modules/editor'
// import tool from './admin/modules/tool'
// import system from './admin/modules/system'

import passport from './admin/modules/passport'
import dashboard from './admin/modules/dashboard'

import user from './admin/modules/user'
import ad from './admin/modules/ad'
import tvbox from './admin/modules/tvbox'
import vip from './admin/modules/vip'
import feedback from './admin/modules/feedback'

import video from './admin/modules/video'

import notice from './admin/modules/notice'
import faq from './admin/modules/faq'
import banner from './admin/modules/banner'

import statistics from './admin/modules/statistics'
import payment from './admin/modules/payment'

import rbac from './admin/modules/rbac'
import system from './admin/modules/system'

import customerService from './admin/modules/customer-service'
import page from './admin/modules/page'
import floating from './admin/modules/floating'

import appVersion from './admin/modules/app-version'

import account from './admin/modules/account'
import LayoutBasic from '@/layouts/basic'

/**
 * 在主框架内显示
 */
const frameInside = [
  {
    path: '/',
    redirect: {
      name: 'dashboard-console',
    },
    component: LayoutBasic,
    children: [
      {
        path: 'index',
        name: 'index',
        redirect: {
          name: 'dashboard-console',
        },
      },
      // {
      //   path: 'log',
      //   name: 'log',
      //   meta: {
      //     // auth: true,
      //     title: '前端日志',
      //   },
      //   component: () => import('@/example/system/log'),
      // },
      {
        // 刷新页面 必须保留
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: {
          beforeRouteEnter(to, from, next) {
            next((instance) => instance.$router.replace(from.fullPath))
          },
          render: (h) => h(),
        },
      },
      {
        // 页面重定向 必须保留
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: {
          beforeRouteEnter(to, from, next) {
            next((instance) =>
              instance.$router.replace(JSON.parse(from.params.route)),
            )
          },
          render: (h) => h(),
        },
      },
      // {
      //   path: 'i18n',
      //   name: 'i18n',
      //   meta: {
      //     // auth: true,
      //     title: '$t:menu.i18n',
      //   },
      //   component: () => import('@/example/i18n'),
      // },
    ],
  },
  dashboard,
  // form,
  // list,
  // search,
  // profile,
  // result,
  // exception,
  // setting,
  // editor,
  // tool,
  // system,

  user,
  ad,
  tvbox,
  vip,
  feedback,

  video,

  notice,
  faq,
  banner,

  statistics,
  payment,

  rbac,
  system,

  customerService,
  page,
  floating,
  appVersion,

  account,
]

/**
 * 在主框架之外显示
 */
const frameOutside = [
  ...passport,
  // {
  //   path: '/test',
  //   name: 'test',
  //   meta: {
  //     // auth: true,
  //     title: '测试',
  //   },
  //   component: () => import('@/views/test.vue'),
  // },
]

/**
 * 错误页面
 */

const errorPage = [
  {
    path: '/error/403',
    name: 'error-403',
    meta: {
      title: '403',
    },
    component: () => import('@/views/error/403'),
  },
  {
    path: '/error/500',
    name: 'error-500',
    meta: {
      title: '500',
    },
    component: () => import('@/views/error/500'),
  },
  {
    path: '*',
    name: 'error-404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/error/404'),
  },
]

// 导出需要显示菜单的
export const frameInsideRoutes = frameInside

// 重新组织后导出
export default [...frameInside, ...frameOutside, ...errorPage]
