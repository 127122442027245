import request from './request'

export default {
  list(data) {
    return request.post('/paymentGateway/list', data)
  },
  info(data) {
    return request.post('/paymentGateway/info', data)
  },
  configProps(data) {
    return request.post('/paymentGateway/configProps', data)
  },
  save(data) {
    return request.post('/paymentGateway/save', data)
  },
  enable(data) {
    return request.post('/paymentGateway/enable', data)
  },
  disable(data) {
    return request.post('/paymentGateway/disable', data)
  },
  delete(data) {
    return request.post('/paymentGateway/delete', data)
  },
}
