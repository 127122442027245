const meta = {
  // auth: false
}

const pre = 'passport-'

export default [
  // {
  //   path: '/passport/login',
  //   name: `${pre}login`,
  //   meta: {
  //     ...meta,
  //     title: '登录',
  //     closable: false,
  //   },
  //   component: () => import('@/views/passport/login'),
  // },
  {
    path: '/passport/signup',
    name: `${pre}signup`,
    meta: {
      ...meta,
      title: '注册',
    },
    component: () => import('@/views/passport/signup'),
  },
  {
    path: '/passport/signin',
    name: `${pre}signin`,
    meta: {
      ...meta,
      title: '登录',
    },
    component: () => import('@/views/passport/signin'),
  },
]
