import storage from '@/utils/storage'

export default {
  namespaced: true,
  state: {
    info: {},
    access: [],
  },
  getters: {},
  mutations: {
    set(state, info) {
      state.info = info
    },
    setAccess(state, access) {
      state.access = access
    },
  },
  actions: {
    init({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const user = storage.user.get()
        commit('set', user)

        const access = storage.access.get() || []
        commit('setAccess', access)

        resolve()
      })
    },
    set({ commit }, user) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('set', user)
        storage.user.set(user)

        resolve()
      })
    },
    clean({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('set', {})
        storage.user.clean()

        resolve()
      })
    },

    setAccess({ commit }, access) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('setAccess', access)
        storage.access.set(access)

        resolve()
      })
    },
    cleanAccess({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('setAccess', [])
        storage.access.clean()

        resolve()
      })
    },
  },
}
