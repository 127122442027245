<template>
  <div>
    <Row :gutter="24">
      <Col :xl="6" :lg="6" :md="0" :sm="0" :xs="0">
        <Card shadow title="用户设置" icon="md-options" :padding="0">
          <CellGroup @on-click="handleChangeCell" class="ivu-pt-8 ivu-pb-8">
            <Cell
              title="基本设置"
              name="info"
              :selected="currentType === 'info'"
            />
            <Cell
              title="修改密码"
              name="change-password"
              :selected="currentType === 'change-password'"
            />
          </CellGroup>
        </Card>
      </Col>
      <Col :xl="18" :lg="18" :md="24" :sm="24" :xs="24">
        <Card :bordered="false" dis-hover>
          <Row>
            <Col :xl="0" :lg="0" :md="24" :sm="24" :xs="24">
              <Tabs :value="currentType" @on-click="handleChangeCell">
                <TabPane label="基本设置" name="info" />
                <TabPane label="修改密码" name="change-password" />
              </Tabs>
            </Col>
          </Row>
          <o-info v-if="currentType === 'info'" />
          <o-change-password v-if="currentType === 'change-password'" />
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import oInfo from './info'
import oChangePassword from './change-password'

export default {
  name: 'setting-account',
  components: { oInfo, oChangePassword },
  data() {
    return {
      currentType: 'info', // info | safety | account | notification
    }
  },
  methods: {
    handleChangeCell(name) {
      this.currentType = name
    },
  },
}
</script>
