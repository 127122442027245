export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  // {
  //   title: '编号',
  //   key: 'id',
  //   minWidth: 40,
  //   maxWidth: 100,
  //   show: true,
  // },
  {
    title: '集数',
    key: 'symbol',
    slot: 'symbol',
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '添加时间',
    key: 'create_time',
    slot: 'create_time',
    show: true,
  },
  {
    title: '清晰度(高亮=已设置, 星星=自定义字幕)',
    key: 'qualities',
    slot: 'qualities',
    show: true,
    width: 400,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 160,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
