import '@babel/polyfill'

// Vue
import Vue from 'vue'
import App from './App'

// 配置
import setting from './setting'

// 混合
import mixinApp from './mixins/app'

// 插件
import plugins from './plugins'

// store
import store from './store'

// iView 和 iView Pro
import ViewUI from 'view-design'
import iViewPro from '../libs/iview-pro/iview-pro.min.js'

// 菜单和路由
import router from './router'
import menuSider from './menu/sider'

// 多语言
// import i18n from './i18n'

// 方法
import { getHeaderName, getMenuSider, getSiderSubmenu } from './utils/system'

// 内置组件
import customComponents from './components'

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme
import 'view-design/dist/styles/iview.css'
import '../libs/iview-pro/styles/iview-pro.css'
import './styles/index.scss'

// if (window) window.$t = (key, value) => i18n.t(key, value)

Vue.use(plugins)

// Vue.use(ViewUI, {
//   i18n: (key, value) => i18n.t(key, value)
// })
Vue.use(ViewUI)
Vue.use(iViewPro)
Vue.use(customComponents)

new Vue({
  mixins: [mixinApp],
  router,
  store,
  // i18n,
  render: (h) => h(App),
  created() {
    this.$store.dispatch('admin/user/init')
    this.$store.dispatch('admin/token/init')
    this.$store.dispatch('admin/log/init')
    this.$store.dispatch('admin/layout/init')
    this.$store.dispatch('admin/menu/init')
    this.$store.dispatch('admin/view/init')
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    $route(to, from) {
      let path = to.matched[to.matched.length - 1].path
      if (!setting.dynamicSiderMenu) {
        let headerName = getHeaderName(path, menuSider)
        if (headerName === null) {
          path = to.path
          headerName = getHeaderName(path, menuSider)
        }
        // 在 404 时，是没有 headerName 的
        if (headerName !== null) {
          this.$store.commit('admin/menu/setHeaderName', headerName)
          this.$store.commit('admin/menu/setMenuSider', menuSider)

          const filterMenuSider = getMenuSider(menuSider, headerName)
          this.$store.commit('admin/menu/setSider', filterMenuSider)
          this.$store.commit('admin/menu/setActivePath', to.path)

          const openNames = getSiderSubmenu(path, menuSider)
          this.$store.commit('admin/menu/setOpenNames', openNames)
        }
      }
      this.appRouteChange(to, from)
    },
  },
}).$mount('#app')
