import request from './request'

export default {
  admin: {
    list(data) {
      return request.post('/rbacAdmin/list', data)
    },
    info(data) {
      return request.post('/rbacAdmin/info', data)
    },
    save(data) {
      return request.post('/rbacAdmin/save', data)
    },
    enable(data) {
      return request.post('/rbacAdmin/enable', data)
    },
    disable(data) {
      return request.post('/rbacAdmin/disable', data)
    },
    forbidden(data) {
      return request.post('/rbacAdmin/forbidden', data)
    },
    delete(data) {
      return request.post('/rbacAdmin/delete', data)
    },

    roleList(data) {
      return request.post('/rbacAdmin/roleList', data)
    },
    setRole(data) {
      return request.post('/rbacAdmin/setRole', data)
    },
  },
  role: {
    list(data) {
      return request.post('/rbacRole/list', data)
    },
    info(data) {
      return request.post('/rbacRole/info', data)
    },
    save(data) {
      return request.post('/rbacRole/save', data)
    },
    enable(data) {
      return request.post('/rbacRole/enable', data)
    },
    disable(data) {
      return request.post('/rbacRole/disable', data)
    },
    delete(data) {
      return request.post('/rbacRole/delete', data)
    },

    all(data) {
      return request.post('/rbacRole/all', data)
    },
    routePermissionList(data) {
      return request.post('/rbacRole/routePermissionList', data)
    },
    setRoutePermission(data) {
      return request.post('/rbacRole/setRoutePermission', data)
    },
  },
  route: {
    list(data) {
      return request.post('/rbacRoute/list', data)
    },
    info(data) {
      return request.post('/rbacRoute/info', data)
    },
    save(data) {
      return request.post('/rbacRoute/save', data)
    },
    enable(data) {
      return request.post('/rbacRoute/enable', data)
    },
    disable(data) {
      return request.post('/rbacRoute/disable', data)
    },
    delete(data) {
      return request.post('/rbacRoute/delete', data)
    },

    all(data) {
      return request.post('/rbacRoute/all', data)
    },
  },
}
