<template>
  <span
    class="layout-header-trigger layout-header-trigger-min"
    @click="toggleFullscreen"
  >
    <Icon custom="i-icon i-icon-full-screen" v-show="!isFullscreen" />
    <Icon custom="i-icon i-icon-exit-full-screen" v-show="isFullscreen" />
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'oHeaderFullscreen',
  computed: {
    ...mapState('admin/layout', ['isFullscreen']),
  },
  methods: {
    ...mapActions('admin/layout', ['toggleFullscreen']),
  },
}
</script>
