<template>
  <div>
    <Form
      ref="basicForm"
      :rules="basicRules"
      :label-width="80"
      label-position="left"
    >
      <Row>
        <Col :span="12">
          <FormItem prop="content" label="热门搜索">
            <div>
              <span v-for="(item, index) in keywordList" :key="index">
                <Input
                  v-if="currentEditKeywordIndex === index"
                  size="small"
                  class="ivu-mr-8"
                  v-model="currentEditKeywordContent"
                  v-width="100"
                  @on-enter="handleChangeKeyword(index)"
                  @on-blur="handleChangeKeyword(index)"
                ></Input>
                <Tag
                  v-else
                  @click.native="handleKeywordEdit(index)"
                  class="keyword"
                  color="primary"
                  closable
                  @on-close="handleKeywordClose(index)"
                >
                  {{ item }}
                </Tag>
              </span>

              <template v-if="keywordList.length < 10">
                <Input
                  v-model="newKeyword"
                  ref="keyword"
                  size="small"
                  v-if="addKeyword"
                  v-width="100"
                  @on-enter="handleAddNewTag"
                  @on-blur="addKeyword = false"
                />
                <Button
                  size="small"
                  type="dashed"
                  icon="md-add"
                  v-else
                  @click="handleOpenNewTag"
                />
              </template>
            </div>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div>
      <Button type="primary" @click="handleSubmit" :loading="submitting">
        提交
      </Button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      currentEditKeywordIndex: null,
      currentEditKeywordContent: '',

      keywordList: ['成龙', '三哥', '胡歌'],
      addKeyword: false,
      newKeyword: '',

      basicRules: {},

      submitting: false,
      loading: false,
    }
  },
  methods: {
    handleGetData() {
      api.config
        .info({
          symbol: 'video',
        })
        .then((data) => {
          // console.log(data)
          if (!data || !data.keywords) return

          this.keywordList = data.keywords
        })
    },
    handleSubmit() {
      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        keywords: this.keywordList,
      }
      api.config
        .saveVideo(params)
        .then(() => {
          this.loading = false

          this.$Message.success('保存成功')
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },
    handleKeywordEdit(i) {
      this.currentEditKeywordIndex = i
      this.currentEditKeywordContent = this.keywordList[i]
    },
    handleChangeKeyword(i) {
      this.currentEditKeywordIndex = null
      this.keywordList[i] = this.currentEditKeywordContent
    },
    handleKeywordClose(i) {
      this.keywordList.splice(i, 1)
    },
    handleOpenNewTag() {
      this.addKeyword = true
      this.$nextTick(() => {
        this.$refs.keyword.focus()
      })
    },
    handleAddNewTag() {
      if (this.newKeyword) {
        this.keywordList.push(this.newKeyword)
        this.newKeyword = ''
        this.addKeyword = false
      }
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>

<style lang="scss">
.keyword {
  cursor: pointer;
}
</style>
