import request from './request'

export default {
  list(data) {
    return request.post('/promotionCategory/list', data)
  },
  info(data) {
    return request.post('/promotionCategory/info', data)
  },
  save(data) {
    return request.post('/promotionCategory/save', data)
  },
  enable(data) {
    return request.post('/promotionCategory/enable', data)
  },
  disable(data) {
    return request.post('/promotionCategory/disable', data)
  },
  // forbidden(data) {
  //   return request.post('/vipCardCategory/forbidden', data)
  // },
  delete(data) {
    return request.post('/promotionCategory/delete', data)
  },
}
