import low from 'lowdb'
import LocalStorage from 'lowdb/adapters/LocalStorage'

const adapter = new LocalStorage('app')
const db = low(adapter)

// 必须定义默认数据 否则无法执行清除
const roots = {
  home: {
    // guest: {
    //   i18n: {
    //     locale: ''
    //   },
    //   logs: []
    // }
  },
  session: {
    // user: {},
    // token: {}
  },
}
db.defaults(roots).write()

export default db

// common
const path = (root, key = null) => {
  let path = root
  if (root === 'home') {
    let uid = db.get('session.user.id').value()
    if (!uid) {
      uid = 'guest'
    }
    path = `${path}.${uid}`
  }
  if (key) {
    path = `${path}.${key}`
  }
  // if (!db.has(path).value()) {
  //   if (isList) {
  //     db.set(path, []).write()
  //   } else {
  //     db.set(path, {}).write()
  //   }
  // }

  // console.log(path)

  return path
}
const exist = (root, key) => {
  return db.has(path(root, key)).value()
}
const read = (root, key) => {
  return db.get(path(root, key)).value()
}
const write = (root, key, value) => {
  return db.set(path(root, key), value).write()
}
const unset = (root, key) => {
  return db.unset(path(root, key)).write()
}

// list
const size = (root, key) => {
  return db.get(path(root, key)).size().value()
}
const push = (root, key, item) => {
  return db.get(path(root, key)).push(item).write()
}
const remove = (root, key, i) => {
  const items = read(path(root, key))
  items.splice(i, 1)
  return write(root, key, items)
}

export { path, exist, read, write, unset, size, push, remove }
