import request from './request'

export default {
  list(data) {
    return request.post('/notice/list', data)
  },
  info(data) {
    return request.post('/notice/info', data)
  },
  save(data) {
    return request.post('/notice/save', data)
  },
  enable(data) {
    return request.post('/notice/enable', data)
  },
  disable(data) {
    return request.post('/notice/disable', data)
  },
  delete(data) {
    return request.post('/notice/delete', data)
  },
}
