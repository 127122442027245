import basic from './basic'
// import app from './app'
// import request from './request'
import layout from './layout'

export default {
  ...basic,
  // app,
  // request,
  // theme: 'default',
  /**
   * 多语言配置
   */
  i18n: {
    // 默认语言
    default: 'zh-CN',
    // 是否根据用户电脑配置自动设置语言（仅第一次有效）
    auto: false,
  },
  /**
   * 布局配置
   */
  // 侧边菜单宽度，单位 px，不可动态修改，需与 setting.less 的 @menuSideWidth 保持一致
  menuSideWidth: 256,
  layout,
  /**
   * 多页 Tabs
   */
  page: {
    // 默认打开的页签
    opened: [],
  },
  /**
   * 功能配置
   */
  // 相同路由，不同参数间进行切换，是否强力更新
  sameRouteForceUpdate: false,
  // 是否使用动态侧边菜单
  dynamicSiderMenu: false,

  // development: true,
}
