<template>
  <span class="layout-header-trigger layout-header-trigger-min">
    <Dropdown
      :trigger="isMobile ? 'click' : 'hover'"
      class="layout-header-user"
      :class="{ 'layout-header-user-mobile': isMobile }"
      @on-click="handleClick"
    >
      <Avatar size="small" :src="info.avatar" v-if="info.avatar" />
      <span class="layout-header-user-name" v-if="!isMobile">
        {{ info.nickname }}
      </span>
      <DropdownMenu slot="list">
        <i-link to="/account/index">
          <DropdownItem>
            <Icon type="ios-settings-outline" />
            <span>用户设置</span>
          </DropdownItem>
        </i-link>
        <DropdownItem divided name="signout">
          <Icon type="ios-log-out" />
          <span>退出登录</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'oHeaderUser',
  computed: {
    ...mapState('admin/user', ['info']),
    ...mapState('admin/layout', ['isMobile', 'logoutConfirm']),
  },
  methods: {
    ...mapActions('admin/passport', ['signout']),
    handleClick(name) {
      if (name === 'signout') {
        this.signout({
          confirm: this.logoutConfirm,
          vm: this,
        })
      }
    },
  },
}
</script>
