<template>
  <div>
    <ButtonGroup size="small">
      <Button :disabled="addNew" type="primary" @click="handleAdd">
        新增
      </Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        启用
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        禁用
      </Button>
      <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button>
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row, index }" slot="name">
        <Input
          v-if="editIndex === index"
          size="small"
          v-model="editInfo.name"
        />
        <span v-else>{{ row.name }}</span>
      </template>

      <template slot-scope="{ row, index }" slot="payment_method">
        <Select
          v-if="editIndex === index"
          size="small"
          v-model="editInfo.payment_method_id"
          transfer
        >
          <Option
            v-for="(item, index) in paymentMethodList"
            :value="item.id"
            :key="index"
          >
            {{ item.name }}
          </Option>
        </Select>
        <span v-else>{{ paymentMethod(row.payment_method_id) }}</span>
      </template>

      <template slot-scope="{ row, index }" slot="payment_gateway">
        <Select
          v-if="editIndex === index"
          size="small"
          v-model="editInfo.payment_gateway_id"
          transfer
        >
          <Option
            v-for="(item, index) in paymentGatewayList"
            :value="item.id"
            :key="index"
          >
            {{ item.name }}
          </Option>
        </Select>
        <span v-else>{{ paymentGateway(row.payment_gateway_id) }}</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <i-switch
          v-if="editIndex === index"
          size="small"
          v-model="editInfo.status"
          :true-value="1"
          :false-value="0"
        />
        <template v-else>
          <Badge v-if="row.status === 1" color="green" text="正常" />
          <Badge v-else-if="row.status === 0" color="yellow" text="禁用" />
        </template>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <template v-if="editIndex === index">
          <ButtonGroup size="small">
            <Button type="success" ghost @click="handleSave(index)">
              <template v-if="!addNew">保存</template>
              <template v-else>添加</template>
            </Button>
            <Button v-if="!addNew" type="primary" ghost @click="handleCancel">
              取消
            </Button>
            <Button
              v-else
              @click="handleCancelAdd(index)"
              type="error"
              ghost
              size="small"
            >
              删除
            </Button>
          </ButtonGroup>
        </template>
        <template v-else>
          <ButtonGroup size="small">
            <Button type="primary" ghost @click="handleEdit(row, index)">
              编辑
            </Button>
            <Button
              type="success"
              ghost
              @click="handleOpenSetting(row.id, row.payment_gateway_id)"
            >
              配置
            </Button>
          </ButtonGroup>
        </template>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑配置'
          : dataDrawer.type === 'new'
          ? '添加配置'
          : '配置信息'
      "
      width="480"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="configProps && dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        :show-message="false"
      >
        <Row :gutter="32" v-for="(item, key) in configProps" :key="key">
          <Col span="24">
            <FormItem prop="title" :label="item.label">
              <template v-if="item.type === 'boolean'">
                <i-switch
                  size="small"
                  v-model="dataInfo[key]"
                  :true-value="1"
                  :false-value="0"
                />
              </template>
              <template v-else-if="item.type === 'string'">
                <Input size="small" v-model="dataInfo[key]" />
              </template>
              <template v-else-if="item.type === 'integer'">
                <Input type="number" size="small" v-model="dataInfo[key]" />
              </template>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitSetting"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  props: {
    paymentMethodList: {
      type: Array,
      default() {
        return []
      },
    },
    paymentGatewayList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      datetimeFormat,
      editIndex: -1,
      addNew: false,
      editInfo: {
        name: '',
        payment_method_id: '',
        payment_gateway_id: '',
        status: 1,
      },

      columns: tableColumns,

      dataInfoRules: {
        // username: [
        //   { required: true, message: '请输入用户名', trigger: 'blur' },
        // ],
      },

      configProps: null,
      channelId: null,
    }
  },
  mixins: [dataListMixin],
  methods: {
    // 支付方式
    paymentMethod(id) {
      const index = this.paymentMethodList.findIndex((item) => item.id === id)
      if (index < 0) {
        return '--'
      }
      return this.paymentMethodList[index].name
    },
    // 支付网关
    paymentGateway(id) {
      const index = this.paymentGatewayList.findIndex((item) => item.id === id)
      if (index < 0) {
        return '--'
      }
      return this.paymentGatewayList[index].name
    },
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      // const { keyword, status } = this.$parent.$parent.$refs.filter.params
      api.paymentChannel
        .list({
          // keyword,
          // status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.payment_channel_list || []
          this.dataTotal = data.payment_channel_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.paymentChannel
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            let tmp = data.payment_channel.config || {}
            if (this.configProps) {
              for (const k in this.configProps) {
                if (tmp[k] === undefined) {
                  tmp[k] = this.configProps[k].default
                }
              }
            }
            this.dataInfo = tmp
            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 取配置属性
    handleGetPaymentGatewayConfigProps(id) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.paymentGateway
          .configProps({
            id,
          })
          .then((data) => {
            if (!data) return

            this.configProps = data.payment_gateway_config_props || null
            resolve()
          })
          .catch(() => {})
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.paymentChannel
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量禁用
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.paymentChannel
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },

    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.paymentChannel
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.paymentChannel
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },

    // 新增
    handleAdd() {
      this.addNew = true
      const row = {
        id: '',
        name: '',
        payment_method_id: '',
        payment_gateway_id: '',
        status: 1,
      }
      this.dataList.unshift(row)
      this.handleEdit(row, 0)
    },

    // 编辑
    handleEdit(row, index) {
      this.editInfo.id = row.id
      this.editInfo.name = row.name
      this.editInfo.payment_method_id = row.payment_method_id
      this.editInfo.payment_gateway_id = row.payment_gateway_id
      this.editInfo.status = row.status
      this.editIndex = index
    },

    // 保存
    handleSave(index) {
      // if (!this.editName || !this.editId || !this.editDepartment) {
      //   this.$Message.error('请填写完整的成员信息！')
      //   return
      // }

      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        id: this.editInfo.id,
        name: this.editInfo.name,
        payment_method_id: this.editInfo.payment_method_id,
        payment_gateway_id: this.editInfo.payment_gateway_id,
        status: this.editInfo.status,
      }
      // console.log(params)
      api.paymentChannel
        .saveBasic(params)
        .then(() => {
          this.loading = false

          this.dataList[index].name = this.editInfo.name
          this.dataList[
            index
          ].payment_method_id = this.editInfo.payment_method_id
          this.dataList[
            index
          ].payment_gateway_id = this.editInfo.payment_gateway_id
          this.dataList[index].status = this.editInfo.status

          this.editIndex = -1
          this.addNew = false

          this.$Message.success('操作成功')
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },

    // 取消编辑
    handleCancel() {
      this.editIndex = -1
      this.addNew = false
    },

    // 取消添加
    handleCancelAdd(index) {
      this.dataList.splice(index, 1)
      this.editIndex = -1
      this.addNew = false
    },

    // 配置
    handleOpenSetting(id, paymentGatewayId) {
      this.channelId = id

      // 取当前渠道对应网关的配置属性信息
      this.handleGetPaymentGatewayConfigProps(paymentGatewayId).then(() => {
        // 取当前渠道配置信息
        this.handleGetDataInfo(id)
          .then(() => {
            this.dataInfoString = this.createDataInfoString(this.dataInfo)
            this.dataDrawer.type = 'edit'
            this.dataDrawer.show = true
            this.handleInitDataInfoForm()
          })
          .catch(() => {})
      })
    },

    // 提交配置
    handleSubmitSetting() {
      // console.log(this.dataInfo)

      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        id: this.channelId,
        config: this.dataInfo,
      }
      // console.log(params)
      api.paymentChannel
        .saveConfig(params)
        .then(() => {
          this.loading = false

          this.channelId = null
          this.dataInfo = null
          this.dataDrawer.show = false
          this.$Message.success('操作成功')
          // this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },

    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
