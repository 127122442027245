<template>
  <div class="setting-account-safety">
    <h2>修改密码</h2>
    <Form
      ref="form"
      :model="adminInfo"
      :rules="rules"
      label-position="top"
      class="ivu-mt"
    >
      <FormItem label="原密码" prop="original_password">
        <Input
          type="password"
          v-model="adminInfo.original_password"
          placeholder="请输入原密码"
        />
      </FormItem>
      <FormItem label="新密码" prop="new_password">
        <Input
          type="password"
          v-model="adminInfo.new_password"
          placeholder="请输入新密码"
        />
      </FormItem>
      <FormItem label="确认密码" prop="confirm_new_password">
        <Input
          type="password"
          v-model="adminInfo.confirm_new_password"
          placeholder="再次输入新密码"
        />
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    const confirmNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'))
      } else if (value !== this.adminInfo.new_password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      adminInfo: {
        original_password: '',
        new_password: '',
        confirm_new_password: '',
      },

      rules: {
        original_password: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: new RegExp('^[0-9A-Za-z_\\-\\.\\?\\/]{4,25}$'),
            message: '密码应为4-25位的字母数字或字符(_-.?/)的组合',
            trigger: 'blur',
          },
        ],
        confirm_new_password: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            validator: confirmNewPassword,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            original_password: this.adminInfo.original_password,
            new_password: this.adminInfo.new_password,
          }
          api.secrity
            .changePassword(params)
            .then((data) => {
              this.$store.dispatch('admin/token/set', data.token, {
                root: true,
              })
              this.adminInfo = {
                original_password: '',
                new_password: '',
                confirm_new_password: '',
              }
              this.$Message.success('修改密码成功!')
            })
            .catch(() => {})
        }
      })
    },
  },
}
</script>

<style lang="scss">
.setting-account-safety {
  .ivu-col {
    p {
      margin-top: 8px;
      color: #808695;
    }
  }
}
</style>
