<template>
  <div>
    <Form
      ref="basicForm"
      :rules="basicRules"
      :label-width="80"
      label-position="left"
    >
      <Row>
        <Col :span="12">
          <FormItem prop="content" label="公告开关">
            <i-switch
              v-model="noticeInfo.switch"
              :true-value="1"
              :false-value="0"
            >
              <span slot="open">开</span>
              <span slot="close">关</span>
            </i-switch>
          </FormItem>
          <FormItem prop="content" label="公告内容">
            <Input
              size="small"
              v-model="noticeInfo.content"
              placeholder="请输入公告内容"
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div>
      <Button type="primary" @click="handleSubmit" :loading="submitting">
        提交
      </Button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      noticeInfo: {
        switch: 0,
        content: '',
      },

      basicRules: {},

      submitting: null,
    }
  },
  methods: {
    handleGetData() {
      api.config
        .info({
          symbol: 'basic',
        })
        .then((data) => {
          // console.log(data)
          if (!data || !data.notice) return

          this.noticeInfo = data.notice
        })
    },
    handleSubmit() {
      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        notice_switch: this.noticeInfo.switch,
        notice_content: this.noticeInfo.content,
      }
      api.config
        .saveBasic(params)
        .then(() => {
          this.loading = false

          this.$Message.success('保存成功')
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
