import request from './request'

export default {
  index(data) {
    return request.post('/summary/index', data)
  },
  onlineUser(data) {
    return request.post('/summary/onlineUser', data)
  },
}
