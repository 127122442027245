<template>
  <div>
    <div class="layout-page-header">
      <PageHeader
        title="业务配置"
        hidden-breadcrumb
        :tab-list="tabList"
        :tab-active-key="tabActiveKey"
        @on-tab-change="handleChangeTab"
      />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <o-basic v-show="tabActiveKey === 'basic'" />
      <o-video v-show="tabActiveKey === 'video'" />
    </Card>
  </div>
</template>
<script>
import oBasic from './basic'
import oVideo from './video'

export default {
  name: 'system-config',
  components: { oBasic, oVideo },
  data() {
    return {
      tabList: [
        {
          label: '基本',
          name: 'basic',
        },
        {
          label: '视频',
          name: 'video',
        },
      ],
      tabActiveKey: 'basic',
    }
  },
  methods: {
    handleGetData() {},
    handleChangeTab({ name }) {
      if (name !== this.tabActiveKey) this.tabActiveKey = name
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
