import { read, write, unset } from './_db'

const root = 'home'
const key = 'setting.layout'

export default {
  set(value) {
    return write(root, key, value)
  },
  get() {
    return Object.assign({}, read(root, key))
  },
  update(k, v) {
    return write(root, `${key}.${k}`, v)
  },
  clean() {
    return unset(root, key)
  },
}
