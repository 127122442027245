import request from './request'

export default {
  list(data) {
    return request.post('/videoReport/list', data)
  },
  info(data) {
    return request.post('/videoReport/info', data)
  },
  enable(data) {
    return request.post('/videoReport/enable', data)
  },
  disable(data) {
    return request.post('/videoReport/disable', data)
  },
  delete(data) {
    return request.post('/videoReport/delete', data)
  },
}
