import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

const pre = 'user-'

export default {
  path: '/user',
  name: 'user',
  redirect: {
    name: `${pre}list`,
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'list',
      name: `${pre}list`,
      meta: {
        ...meta,
        title: '用户管理',
      },
      component: () => import('@/views/user/list'),
    },
  ],
}
