import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

const pre = 'dashboard-'

export default {
  path: '/dashboard',
  name: 'dashboard',
  redirect: {
    name: `${pre}console`,
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'console',
      name: `${pre}console`,
      meta: {
        ...meta,
        title: '主控台',
        closable: false,
      },
      component: () => import('@/views/dashboard/console'),
    },
    {
      path: 'monitor',
      name: `${pre}monitor`,
      meta: {
        ...meta,
        title: '监控页',
      },
      component: () => import('@/views/dashboard/monitor'),
    },
    {
      path: 'workplace',
      name: `${pre}workplace`,
      meta: {
        ...meta,
        title: '工作台',
      },
      component: () => import('@/views/dashboard/workplace'),
    },
  ],
}
