<template>
  <div>
    <Form ref="dataInfoForm" :label-width="20" style="margin-bottom: 50px">
      <FormItem>
        <CheckboxGroup size="small" v-model="roles">
          <Checkbox
            v-for="(item, index) in roleList"
            :label="item.id"
            :key="index"
          >
            {{ item.name }}
          </Checkbox>
        </CheckboxGroup>
      </FormItem>
    </Form>
    <div class="page-sys-user-drawer-footer">
      <Button type="primary" @click="handleSubmit">确定</Button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  props: {
    adminId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      roleList: [],

      roles: [],
    }
  },
  methods: {
    handleGetData() {
      if (this.adminId < 1) {
        return
      }
      api.rbac.role.all({}).then((data) => {
        this.roleList = data.rbac_role_list || []
      })

      api.rbac.admin
        .roleList({
          id: this.adminId,
        })
        .then((data) => {
          let roles = []
          if (data.role_list && data.role_list.length) {
            data.role_list.forEach((item) => {
              roles.push(item.id)
            })
          }
          this.roles = roles
        })
    },
    handleSubmit() {
      const params = {
        id: this.adminId,
        roles: this.roles,
      }

      api.rbac.admin.setRole(params).then(() => {
        this.$Message.success('操作成功')
        this.$emit('completed', true)
        this.roles = []
      })
    },
  },
}
</script>
