<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="分类管理" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt" ref="card">
      <!-- <o-filter ref="filter" @on-submit="handleGetData" /> -->
      <o-table ref="table" @on-fullscreen="handleFullscreen" />
    </Card>
  </div>
</template>
<script>
import screenfull from 'screenfull'
// import oFilter from './filter'
import oTable from './table'

export default {
  name: 'video-category',
  components: {
    // oFilter,
    oTable,
  },
  data() {
    return {}
  },
  methods: {
    handleGetData() {
      this.$refs.table.handleGetDataList()
    },
    handleFullscreen(state) {
      if (state) {
        screenfull.request(this.$refs.card.$el)
      } else {
        screenfull.exit()
      }
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
