import store from '@/store'
import utils from '@/utils'
import setting from '@/setting'
import { Message, Notice } from 'view-design'

export default {
  log(err) {
    // 添加到日志
    store.dispatch('admin/log/push', {
      message: '数据请求异常',
      type: 'error',
      meta: {
        error: err,
      },
    })

    // 打印到控制台
    if (process.env.NODE_ENV === 'development') {
      utils.log.error('>>>>>> Error >>>>>>')
      console.log(err)
    }

    // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
    if (setting.errorModalType === 'Message') {
      Message.error({
        content: err.message,
        duration: setting.modalDuration,
      })
    } else if (setting.errorModalType === 'Notice') {
      Notice.error({
        title: '提示',
        desc: err.message,
        duration: setting.modalDuration,
      })
    }
  },

  create(msg) {
    const err = new Error(msg)
    this.log(err)
    throw err
  },

  show(msg) {
    if (setting.errorModalType === 'Message') {
      Message.error({
        background: true,
        content: msg,
        duration: setting.modalDuration,
      })
    } else if (setting.errorModalType === 'Notice') {
      Notice.error({
        title: '提示',
        desc: msg,
        duration: setting.modalDuration,
      })
    }
  },
}
