export default [
  // {
  //   type: 'selection',
  //   width: 55,
  //   show: true,
  // },
  {
    title: '订单号',
    key: 'no',
    width: 220,
    show: false,
  },
  {
    title: '用户',
    key: 'uid',
    slot: 'user',
    // width: 230,
    show: true,
  },
  {
    title: '被充值用户',
    key: 'pay_for_other',

    show: false,
  },

  {
    title: '支付方式',
    key: 'payment_method',
    slot: 'payment_method',
    show: true,
  },
  {
    title: '支付网关',
    key: 'payment_gateway',
    slot: 'payment_gateway',
    show: false,
  },
  {
    title: '支付渠道',
    key: 'payment_channel',
    slot: 'payment_channel',
    width: 150,
    show: false,
  },
  {
    title: '金额',
    key: 'amount',
    slot: 'amount',
    show: true,
    // width: 120,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
    // width: 110,
  },
  {
    title: '订单渠道',
    key: 'platform',
    slot: 'platform',
    show: true,
    // width: 110,
  },
  {
    title: '第三方流水号',
    key: 'pay_sn',
    slot: 'pay_sn',
    width: 240,
    show: true,
  },
  {
    title: '下单时间',
    key: 'create_time',
    slot: 'create_time',
    // width: 170,
    show: true,
  },
  {
    title: '支付时间',
    key: 'pay_time',
    slot: 'pay_time',
    // width: 170,
    show: true,
  },
  // {
  //   title: '操作',
  //   key: 'action',
  //   slot: 'action',
  //   width: 120,
  //   align: 'center',
  //   fixed: 'right',
  //   show: true,
  // },
]
