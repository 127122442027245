import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

const pre = 'rbac-'

export default {
  path: '/rbac',
  name: 'rbac',
  redirect: {
    name: `${pre}admin`,
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'admin',
      name: `${pre}admin`,
      meta: {
        ...meta,
        title: '管理员',
      },
      component: () => import('@/views/rbac/admin'),
    },
    {
      path: 'role',
      name: `${pre}role`,
      meta: {
        ...meta,
        title: '角色',
      },
      component: () => import('@/views/rbac/role'),
    },
    {
      path: 'route',
      name: `${pre}route`,
      meta: {
        ...meta,
        title: '前端路由',
      },
      component: () => import('@/views/rbac/route'),
    },
    {
      path: 'node',
      name: `${pre}node`,
      meta: {
        ...meta,
        title: '后端节点',
      },
      component: () => import('@/views/rbac/node'),
    },
  ],
}
