import screenfull from 'screenfull'
import setting from '@/setting'
import storage from '@/utils/storage'

export default {
  namespaced: true,
  state: {
    ...setting.layout,
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    isFullscreen: false,
  },
  getters: {},
  mutations: {
    setDevice(state, type) {
      state.isMobile = false
      state.isTablet = false
      state.isDesktop = false
      state[`is${type}`] = true
    },
    setMenuCollapse(state, collapse) {
      state.menuCollapse = collapse
    },
    setFullscreen(state, isFullscreen) {
      state.isFullscreen = isFullscreen
    },
    updateLayoutSetting(state, { key, value }) {
      state[key] = value
    },
    resetLayoutSetting(state) {
      Object.assign(state, setting.layout)
    },
  },
  actions: {
    init({ state, commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        // listenFullscreen
        if (screenfull.enabled) {
          screenfull.on('change', () => {
            if (!screenfull.isFullscreen) {
              commit('setFullscreen', false)
            }
          })
        }

        // load storage setting
        Object.assign(state, storage.setting.get())

        resolve()
      })
    },
    toggleFullscreen({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        if (screenfull.isFullscreen) {
          screenfull.exit()
          commit('setFullscreen', false)
        } else {
          screenfull.request()
          commit('setFullscreen', true)
        }

        resolve()
      })
    },
    updateLayoutSetting({ commit }, { key, value }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('updateLayoutSetting', { key, value })
        storage.setting.update(key, value)

        resolve()
      })
    },
    resetLayoutSetting({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('resetLayoutSetting')
        storage.setting.clean()

        resolve()
      })
    },
  },
}
