import router from '@/router'
import api from '@/api'
import { Modal } from 'view-design'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /*
    signup({ dispatch }, params) {
      return new Promise((resolve, reject) => {
        api.passport
          .signin(params)
          .then((data) => {
            if (data === undefined) return

            dispatch('admin/user/set', data.user, { root: true })
            dispatch('admin/token/set', data.token, { root: true })
            // todo: 从持久化数据加载一系列的设置
            // todo: 把游客的数据移到对应用户中

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    */
    signin({ dispatch }, params) {
      return new Promise((resolve, reject) => {
        api.passport
          .signin(params)
          .then((data) => {
            if (data === undefined) return

            dispatch('admin/user/set', data.admin, { root: true })
            dispatch('admin/user/setAccess', data.access, { root: true })
            dispatch('admin/token/set', data.token, { root: true })
            // todo: 从持久化数据加载一系列的设置
            // todo: 把游客的数据移到对应用户中

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    signout({ dispatch }, { confirm = false } = {}) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const func = () => {
          dispatch('admin/user/clean', null, { root: true })
          dispatch('admin/user/cleanAccess', null, { root: true })
          dispatch('admin/token/clean', null, { root: true })
          // todo: 从持久化数据中删除对应用户的数据
          // todo: 把对应用户的数据移到游客中

          router.push({
            name: 'passport-signin',
          })
        }

        if (confirm) {
          Modal.confirm({
            title: '退出登录确认',
            content:
              '您确定退出登录当前账户吗？打开的标签页和个人设置将会保存。',
            onOk() {
              func()
            },
          })
        } else {
          func()
        }

        resolve()
      })
    },
  },
}
