import request from './request'

export default {
  list(data) {
    return request.post('/faq/list', data)
  },
  info(data) {
    return request.post('/faq/info', data)
  },
  save(data) {
    return request.post('/faq/save', data)
  },
  enable(data) {
    return request.post('/faq/enable', data)
  },
  disable(data) {
    return request.post('/faq/disable', data)
  },
  delete(data) {
    return request.post('/faq/delete', data)
  },

  category(data) {
    return request.post('/faq/category', data)
  },
}
