<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="片源标题" prop="title" label-for="title">
          <Input
            size="small"
            v-model="params.title"
            clearable
            placeholder="片源标题"
          />
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="弹幕内容" prop="content" label-for="content">
          <Input
            size="small"
            v-model="params.content"
            clearable
            placeholder="弹幕内容"
          />
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="用户名" prop="username" label-for="username">
          <Input
            size="small"
            v-model="params.keyword"
            clearable
            placeholder="用户名"
          />
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="发送时间" prop="date" label-for="date">
          <DatePicker
            size="small"
            v-model="params.date"
            clearable
            type="daterange"
            :options="dateOptions"
            placeholder="请选择"
            v-width="'100%'"
          />
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem
          label="栏目"
          prop="parent_category_id"
          label-for="parent_category_id"
        >
          <Select
            size="small"
            v-model="params.parent_category_id"
            clearable
            placeholder="请选择"
            @on-change="handleParentCategoryChange"
          >
            <Option
              v-for="(item, index) in topCategoryList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="类型" prop="category_id" label-for="category_id">
          <Select
            size="small"
            v-model="params.category_id"
            clearable
            placeholder="请选择"
          >
            <Option
              v-for="(item, index) in subCategoryList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <!-- <Col v-bind="grid">
        <FormItem label="状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择"
          >
            <Option value="1">显示</Option>
            <Option value="0">屏蔽</Option>
          </Select>
        </FormItem>
      </Col> -->

      <Col v-bind="grid2" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'

export default {
  props: {
    categoryList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 8,
        sm: 24,
        xs: 24,
      },
      grid2: {
        xl: 18,
        lg: 6,
        md: 8,
        sm: 24,
        xs: 24,
      },
      smallGrid: {
        xl: 3,
        lg: 4,
        md: 6,
        sm: 8,
        xs: 12,
      },

      params: {
        username: '',
        content: '',
        title: '',
        parent_category_id: '',
        category_id: '',
        date: [],
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
    topCategoryList() {
      return this.categoryList.filter((item) => item.pid === 0) || []
    },
    subCategoryList() {
      return this.categoryList.filter(
        (item) => item.pid === this.params.parent_category_id,
      )
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
    handleParentCategoryChange() {
      this.params.category_id = ''
    },
  },
}
</script>
