import request from './request'

export default {
  list(data) {
    return request.post('/feedback/list', data)
  },
  info(data) {
    return request.post('/feedback/info', data)
  },
  enable(data) {
    return request.post('/feedback/enable', data)
  },
  disable(data) {
    return request.post('/feedback/disable', data)
  },
  delete(data) {
    return request.post('/feedback/delete', data)
  },
  reply(data) {
    return request.post('/feedback/reply', data)
  },

  /* category */
  category: {
    list(data) {
      return request.post('/feedbackCategory/list', data)
    },
    info(data) {
      return request.post('/feedbackCategory/info', data)
    },
    save(data) {
      return request.post('/feedbackCategory/save', data)
    },
    enable(data) {
      return request.post('/feedbackCategory/enable', data)
    },
    disable(data) {
      return request.post('/feedbackCategory/disable', data)
    },
    delete(data) {
      return request.post('/feedbackCategory/delete', data)
    },
  },
}
