export default {
  data() {
    return {
      columns: [],
      selectedIds: [],
      loading: false,
      dataList: [],
      dataTotal: 0,
      page: 1,
      pagesize: 10,
      tableFullscreen: false,

      dataInfo: null, // 当前编辑数据信息
      dataInfoRules: {},
      dataInfoFormReady: true,
      dataInfoString: null,

      dataDrawer: {
        show: false,
        type: 'edit', // edit || read || new
        styles: {
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static',
        },
      },

      submitting: false,
    }
  },
  computed: {
    // 动态设置列
    tableColumns() {
      const columns = [...this.columns]
      return columns.filter((item) => item.show)
    },
    // 组装好的可供api使用的ids
    ids() {
      return this.selectedIds.map((item) => item.id).join(',')
    },
  },
  methods: {
    createDataInfoString(dataInfo) {
      return JSON.stringify(dataInfo)
    },
    // 表格全屏
    handleFullscreen() {
      this.tableFullscreen = !this.tableFullscreen
      this.$emit('on-fullscreen', this.tableFullscreen)
    },
    // 刷新表格数据
    handleRefresh() {
      this.handleGetDataList()
    },
    // 切换页码
    handleChangePage(page) {
      this.page = page
      this.handleGetDataList()
    },
    // 切换每页条数
    handleChangePageSize(pagesize) {
      this.page = 1
      this.pagesize = pagesize
      this.handleGetDataList()
    },

    // 选中一项，将数据添加至已选项中
    handleSelect(selection, row) {
      this.selectedIds.push(row)
    },
    // 取消选中一项，将取消的数据从已选项中删除
    handleSelectCancel(selection, row) {
      const index = this.selectedIds.findIndex((item) => item.id === row.id)
      this.selectedIds.splice(index, 1)
    },
    // 当前页全选时，判断已选数据是否存在，不存在则添加
    handleSelectAll(selection) {
      selection.forEach((item) => {
        if (this.selectedIds.findIndex((i) => i.id === item.id) < 0) {
          this.selectedIds.push(item)
        }
      })
    },
    // 清空所有已选项
    handleClearSelect() {
      this.selectedIds = []
    },
    // 关闭编辑
    handleCloseEdit() {
      // 判断内容是否修改，没修改则直接关闭
      if (this.dataDrawer.type === 'edit') {
        if (this.dataInfoString !== this.createDataInfoString(this.dataInfo)) {
          // eslint-disable-next-line
          return new Promise((resolve, reject) => {
            this.$Modal.confirm({
              title: '关闭确认',
              content: '您已经修改了信息，是否直接关闭？',
              onOk: () => {
                resolve()
              },
            })
          })
        }
      }
      // 判断内容是否修改，没修改则直接关闭
      else if (this.dataDrawer.type === 'new') {
        if (this.dataInfoString !== this.createDataInfoString(this.dataInfo)) {
          // eslint-disable-next-line
          return new Promise((resolve, reject) => {
            this.$Modal.confirm({
              title: '关闭确认',
              content: '您已经填写了信息，是否直接关闭？',
              onOk: () => {
                resolve()
              },
            })
          })
        }
      }
    },
  },
}
