export default {
  path: '/video',
  title: '视频管理',
  auth: 'video',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/video/list',
      title: '片源管理',
      auth: 'video-list',
    },
    // {
    //   path: '/video/server',
    //   title: '服务器管理',
    //   auth: 'video-server',
    // },
    {
      path: '/video/category',
      title: '分类管理',
      auth: 'video-category',
    },
    {
      path: '/video/filter',
      title: '筛选管理',
      auth: 'video-filter',
    },
    {
      path: '/video/report',
      title: '报错管理',
      auth: 'video-report',
    },
  ],
}
