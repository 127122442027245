<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="盒子管理" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt" ref="card">
      <o-filter
        ref="filter"
        :platforms="platforms"
        :types="types"
        :category-list="categoryList"
        @on-submit="handleGetData"
      />
      <o-table
        ref="table"
        :platforms="platforms"
        :types="types"
        :category-list="categoryList"
        @on-fullscreen="handleFullscreen"
      />
    </Card>
  </div>
</template>
<script>
import screenfull from 'screenfull'
import oFilter from './filter'
import oTable from './table'
import api from '@/api'

export default {
  name: 'ad-list',
  components: {
    oFilter,
    oTable,
  },
  data() {
    return {
      platforms: [
        {
          value: 1,
          label: 'PC',
        },
        {
          value: 2,
          label: '移动',
        },
      ],
      types: [
        {
          value: 1,
          label: '图片',
        },
        {
          value: 2,
          label: '视频',
        },
        // {
        //   value: 3,
        //   label: '文字',
        // },
      ],
      categoryList: [],
    }
  },
  methods: {
    handleGetData() {
      this.handleGetCategoryList()
      this.$refs.table.handleGetDataList()
    },
    handleFullscreen(state) {
      if (state) {
        screenfull.request(this.$refs.card.$el)
      } else {
        screenfull.exit()
      }
    },
    // 取分类列表
    handleGetCategoryList() {
      api.ad
        .category({
          // status: 1,
        })
        .then((data) => {
          if (!data) return
          this.categoryList = data.ad_category_list || []
        })
        .catch(() => {})
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
