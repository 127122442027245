const pre = '/dashboard/'

export default {
  path: '/dashboard',
  title: 'Dashboard',
  header: 'home',
  custom: 'o-icon o-icon-dashboard',
  children: [
    {
      path: `${pre}console`,
      title: '主控台',
      // auth: 'dashboard-console',
    },
    // {
    //   path: `${pre}monitor`,
    //   title: '监控页',
    // },
    // {
    //   path: `${pre}workplace`,
    //   title: '工作台',
    //   subtitle: 'Workplace',
    // },
  ],
}
