import request from './request'

export default {
  list(data) {
    return request.post('/user/list', data)
  },
  info(data) {
    return request.post('/user/info', data)
  },
  save(data) {
    return request.post('/user/save', data)
  },
  enable(data) {
    return request.post('/user/enable', data)
  },
  disable(data) {
    return request.post('/user/disable', data)
  },
  forbidden(data) {
    return request.post('/user/forbidden', data)
  },
  delete(data) {
    return request.post('/user/delete', data)
  },
  enableVip(data) {
    return request.post('/user/enablevip', data)
  },
  disableVip(data) {
    return request.post('/user/disablevip', data)
  },

  changePassword(data) {
    return request.post('/user/changePassword', data)
  },
  rechargeList(data) {
    return request.post('/user/rechargeList', data)
  },
  activeList(data) {
    return request.post('/user/activeList', data)
  },
}
