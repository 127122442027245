<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="客服中心" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <o-customer-service />
    </Card>
  </div>
</template>
<script>
import oCustomerService from './customer-service'

export default {
  name: 'customer-service-list',
  components: {
    oCustomerService,
  },
  data() {
    return {}
  },
  methods: {
    handleGetData() {},
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
