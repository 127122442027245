<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="筛选管理" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <o-video />
    </Card>
  </div>
</template>
<script>
import oVideo from './video'

export default {
  name: 'system-config',
  components: {
    oVideo,
  },
  data() {
    return {}
  },
  methods: {
    handleGetData() {},
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
