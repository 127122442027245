import storage from '@/utils/storage'
import { filterToken } from '@/utils/storage/token'

export default {
  namespaced: true,
  state: {
    token: {},
  },
  getters: {
    value(state) {
      const token = filterToken(state.token)
      if (Object.keys(token).length === 0) {
        return null
      }
      return token.token
    },
  },
  mutations: {
    set(state, token) {
      state.token = token
    },
  },
  actions: {
    init({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const token = storage.token.get()
        commit('set', token)

        resolve()
      })
    },
    set({ commit }, token) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        token = filterToken(token)
        commit('set', token)
        storage.token.set(token)

        resolve()
      })
    },
    clean({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('set', {})
        storage.token.clean()

        resolve()
      })
    },
  },
}
