import request from './request'

export default {
  save(data) {
    return request.post('/videoSource/save', data)
  },

  server(data) {
    return request.post('/videoSource/server', data)
  },
}
