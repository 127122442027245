<template>
  <div>
    <ButtonGroup size="small">
      <Button type="primary" @click="handleOpenCreate">新增</Button>
    </ButtonGroup>
    <ButtonGroup size="small" class="ivu-ml-8">
      <Button
        type="success"
        :disabled="selectedIds.length < 1"
        @click="handleEnableMultiple"
      >
        启用
      </Button>
      <Button
        type="warning"
        :disabled="selectedIds.length < 1"
        @click="handleDisableMultiple"
      >
        禁用
      </Button>
      <!-- <Button
        @click="handleDeleteMultiple"
        type="error"
        :disabled="selectedIds.length < 1"
      >
        删除
      </Button> -->
    </ButtonGroup>

    <div class="ivu-inline-block ivu-fr">
      <Tooltip
        class="ivu-ml"
        :content="tableFullscreen ? '退出全屏' : '全屏'"
        placement="top"
      >
        <i-link @click.native="handleFullscreen">
          <Icon
            :custom="
              tableFullscreen
                ? 'i-icon i-icon-exit-full-screen'
                : 'i-icon i-icon-full-screen'
            "
          />
        </i-link>
      </Tooltip>
      <Tooltip class="ivu-ml" content="刷新" placement="top">
        <i-link @click.native="handleRefresh">
          <Icon custom="i-icon i-icon-refresh" />
        </i-link>
      </Tooltip>
      <Dropdown trigger="click">
        <Tooltip class="ivu-ml" content="列设置" placement="top">
          <i-link>
            <Icon type="md-options" />
          </i-link>
        </Tooltip>
        <DropdownMenu slot="list">
          <div class="ivu-p-8">列展示</div>
          <Divider size="small" class="ivu-mt-8 ivu-mb-8" />
          <template v-for="item in columns">
            <li
              class="ivu-dropdown-item"
              :key="item.title"
              v-if="item.title"
              @click="item.show = !item.show"
            >
              <Checkbox v-model="item.show"></Checkbox>
              <span>{{ item.title }}</span>
            </li>
          </template>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Table
      ref="table"
      :columns="tableColumns"
      :data="dataList"
      :loading="loading"
      class="ivu-mt"
      @on-select="handleSelect"
      @on-select-cancel="handleSelectCancel"
      @on-select-all="handleSelectAll"
      @on-select-all-cancel="handleClearSelect"
    >
      <template slot-scope="{ row }" slot="period">
        {{ row.period }} 天
      </template>
      <template slot-scope="{ row }" slot="price">
        {{ row.price }} {{ row.currency }}
      </template>
      <template slot-scope="{ row }" slot="status">
        <Badge v-if="row.status === 1" color="green" text="正常" />
        <Badge v-else-if="row.status === 0" color="yellow" text="禁用" />
      </template>
      <template slot-scope="{ row }" slot="action">
        <ButtonGroup size="small">
          <Button type="primary" ghost @click="handleEdit(row.id)">
            编辑
          </Button>
          <!-- <Button type="error" ghost @click="handleDelete(row.id)">
            删除
          </Button> -->
        </ButtonGroup>
      </template>
    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page
        size="small"
        :total="dataTotal"
        :current.sync="page"
        :page-size-opts="[10, 20, 50, 100]"
        show-total
        show-sizer
        show-elevator
        :page-size="pagesize"
        @on-change="handleChangePage"
        @on-page-size-change="handleChangePageSize"
      />
    </div>

    <Drawer
      :styles="dataDrawer.styles"
      v-model="dataDrawer.show"
      :title="
        dataDrawer.type === 'edit'
          ? '编辑点卡套餐'
          : dataDrawer.type === 'new'
          ? '添加点卡套餐'
          : '点卡套餐信息'
      "
      width="540"
      :before-close="handleCloseEdit"
      :transfer="false"
      :mask-closable="false"
    >
      <Form
        v-if="dataInfo && dataInfoFormReady"
        ref="dataInfoForm"
        :model="dataInfo"
        :rules="dataInfoRules"
        :disabled="dataDrawer.type === 'read'"
        label-position="top"
        label-colon
        hide-required-mark
      >
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="name" label="名称">
              <Input
                size="small"
                v-model="dataInfo.name"
                placeholder="请输入名称"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="period" label="周期(天)">
              <Input
                size="small"
                v-model="dataInfo.period"
                placeholder="请输入周期"
              />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="level" label="类型">
              <Select
                size="small"
                v-model="dataInfo.level"
                placeholder="请选择类型"
              >
                <Option
                  v-for="(item, index) in level"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.label }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="price" label="购买价格">
              <Input
                size="small"
                v-model="dataInfo.price"
                placeholder="请输入购买价格"
              />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="currency" label="币种">
              <Select
                size="small"
                v-model="dataInfo.currency"
                placeholder="请选择币种"
              >
                <Option
                  v-for="(item, index) in currencyList"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.label }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="integral" label="兑换所需积分">
              <Input
                size="small"
                v-model="dataInfo.integral"
                placeholder="请输入兑换所需积分"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem prop="sort" label="排序">
              <Input
                size="small"
                v-model="dataInfo.sort"
                placeholder="-99 ~ 99"
                maxlength="3"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="24">
            <FormItem prop="status" label="状态">
              <RadioGroup size="small" v-model="dataInfo.status">
                <Radio :label="1"><span>正常</span></Radio>
                <Radio :label="0"><span>禁用</span></Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="page-sys-user-drawer-footer">
        <Button
          type="primary"
          @click="handleSubmitEdit"
          :loading="submitting"
        >
          提交
        </Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { datetimeFormat } from '@/utils/datetime'
import api from '@/api'
// import { cloneDeep } from 'lodash'
import tableColumns from './table-columns'
import dataListMixin from '@/mixins/data-list'

export default {
  data() {
    return {
      datetimeFormat,
      level: [
        { label: '天卡', value: 1 },
        { label: '周卡', value: 2 },
        { label: '月卡', value: 3 },
        { label: '季卡', value: 4 },
        { label: '年卡', value: 5 },
        { label: '情侣卡', value: 6 },
        { label: '家庭卡', value: 7 },
      ],
      currencyList: [
        {
          value: 'CNY',
          label: 'CNY',
        },
        {
          value: 'USD',
          label: 'USD',
        },
        {
          value: 'JPY',
          label: 'JPY',
        },
        {
          value: 'EUR',
          label: 'EUR',
        },
        {
          value: 'GBP',
          label: 'GBP',
        },
        {
          value: 'KRW',
          label: 'KRW',
        },
        {
          value: 'HKD',
          label: 'HKD',
        },
        {
          value: 'AUD',
          label: 'AUD',
        },
        {
          value: 'CAD',
          label: 'CAD',
        },
      ],
      columns: tableColumns,

      dataInfoRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
      },
    }
  },
  mixins: [dataListMixin],
  methods: {
    // 取数据列表
    handleGetDataList() {
      if (this.loading) return
      this.loading = true

      // 下面的 params 是获取的表单查询参数
      // const { category_id, status } = this.$parent.$parent.$refs.filter.params
      api.vipCardCategory
        .list({
          // category_id,
          // status,
          page: this.page, // 页数
          pagesize: this.pagesize, // 每页数量
        })
        .then((data) => {
          if (!data) return

          this.handleClearSelect()
          this.dataList = data.vip_card_category_list || []
          this.dataTotal = data.vip_card_category_total
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 取单一数据
    handleGetDataInfo(id) {
      if (this.loading) return
      this.loading = true

      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        api.vipCardCategory
          .info({
            id,
          })
          .then((data) => {
            if (!data) return

            this.dataInfo = data.vip_card_category || null

            resolve()
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false
          })
      })
    },
    // 批量启用
    handleEnableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.vipCardCategory
        .enable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量置已使用
    handleDisableMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.vipCardCategory
        .disable({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量置禁用
    handleForbiddenMultiple() {
      if (this.loading) return
      this.loading = true

      const ids = this.ids
      api.vipCardCategory
        .forbidden({
          ids,
        })
        .then(() => {
          this.loading = false

          this.$Modal.remove()
          this.$Message.success('操作成功')
          // 完成后刷新数据
          // this.handleClearSelect()
          // this.handleChangePage(1)
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 批量删除
    handleDeleteMultiple() {
      const ids = this.ids
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除所选数据项？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.vipCardCategory
            .delete({
              ids,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.vipCardCategory
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleGetDataInfo(id)
        .then(() => {
          this.dataInfoString = this.createDataInfoString(this.dataInfo)
          this.dataDrawer.type = 'edit'
          this.dataDrawer.show = true
          this.handleInitDataInfoForm()
        })
        .catch(() => {})
    },

    // 详情
    handleClickRow(row) {
      console.log('click row: row -> ')
      console.log(row)
      // this.dataInfo = cloneDeep(this.handleGetUser(row.id))
      // this.dataDrawer.type = 'read'
      // this.dataDrawer.show = true
      // this.handleInitDataInfoForm()
    },
    // 新增
    handleOpenCreate() {
      this.dataInfo = {
        id: 0,
        name: '',
        period: '',
        level: '',
        price: '',
        currency: 'USD',
        integral: '',
        sort: 50,
        status: 1,
      }

      this.dataInfoString = this.createDataInfoString(this.dataInfo)
      this.dataDrawer.type = 'new'
      this.dataDrawer.show = true
      this.handleInitDataInfoForm()
    },
    handleSubmitEdit() {
      this.$refs.dataInfoForm.validate((valid) => {
        if (valid) {
          if (this.loading) return
          if (this.submitting) return
          this.loading = true
          this.submitting = true

          const params = {
            id: this.dataInfo.id,
            name: this.dataInfo.name,
            period: this.dataInfo.period,
            level: this.dataInfo.level,
            price: this.dataInfo.price,
            currency: this.dataInfo.currency,
            integral: this.dataInfo.integral,
            sort: this.dataInfo.sort,
            status: this.dataInfo.status,
          }
          // console.log(params)
          api.vipCardCategory
            .save(params)
            .then(() => {
              this.loading = false

              this.dataDrawer.show = false
              this.$Message.success('修改成功')
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
            .finally(() => {
              this.submitting = false
            })
        }
      })
    },
    handleInitDataInfoForm() {
      this.dataInfoFormReady = false
      this.$nextTick(() => {
        this.dataInfoFormReady = true
      })
    },
  },
}
</script>
<style lang="scss">
.page-sys-user {
  &-drawer {
    &-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
    &-form {
      .ivu-input[disabled],
      fieldset[disabled] .ivu-input,
      .ivu-select-disabled .ivu-select-selection {
        background-color: #fff;
        color: inherit;
      }
      &-region {
        .i-region-transfer {
          width: 270px;
        }
        .i-region-drop-main {
          width: 270px !important;
          height: 200px;
          overflow: auto;
          column-count: 2;
          &-item {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
