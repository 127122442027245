<template>
  <div>
    <ButtonGroup size="small">
      <Button :disabled="addNew" type="primary" @click="handleAdd">
        新增
      </Button>
    </ButtonGroup>
    <br />
    <br />

    <Row :gutter="32">
      <Col
        class="customer-service-item"
        v-bind="grid"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <Card>
          <p slot="title">编号: {{ item.id }}</p>
          <div slot="extra">
            <template v-if="editIndex === index">
              <ButtonGroup size="small">
                <Button type="success" ghost @click="handleSave(index)">
                  <template v-if="!addNew">保存</template>
                  <template v-else>添加</template>
                </Button>
                <Button
                  v-if="!addNew"
                  type="primary"
                  ghost
                  @click="handleCancel"
                >
                  取消
                </Button>
                <Button
                  v-else
                  @click="handleCancelAdd(index)"
                  type="error"
                  ghost
                  size="small"
                >
                  删除
                </Button>
              </ButtonGroup>
            </template>
            <template v-else>
              <ButtonGroup size="small">
                <Button type="primary" ghost @click="handleEdit(item, index)">
                  编辑
                </Button>
                <Button type="error" ghost @click="handleDelete(item.id)">
                  删除
                </Button>
              </ButtonGroup>
            </template>
          </div>

          <Row :gutter="16">
            <Col :span="8">
              <template v-if="editIndex === index">
                <img
                  v-if="editInfo.wechat_qrcode"
                  class="wechat-qrcode"
                  :src="editInfo.wechat_qrcode"
                  alt="微信二维码"
                />
                <Upload
                  action=""
                  :show-upload-list="false"
                  :before-upload="handleUploadWechatQrcode"
                >
                  <Button icon="ios-cloud-upload-outline">上传</Button>
                </Upload>
              </template>

              <img
                v-else-if="item.wechat_qrcode"
                class="wechat-qrcode"
                :src="item.wechat_qrcode"
                alt="微信二维码"
              />
            </Col>
            <Col :span="16">
              <p class="info-item">
                <span class="label">名称:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.name"
                  ></Input>
                  <template v-else>{{ item.name }}</template>
                </span>
                <span class="state"></span>
              </p>
              <p class="info-item">
                <span class="label">备注:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.remark"
                  ></Input>
                  <template v-else>{{ item.remark }}</template>
                </span>
                <span class="state"></span>
              </p>

              <p class="info-item">
                <span class="label">微信号:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.wechat"
                  ></Input>
                  <template v-else>{{ item.wechat }}</template>
                </span>
                <span class="state">
                  <i-switch
                    v-if="editIndex === index"
                    size="small"
                    v-model="editInfo.wechat_state"
                    :true-value="1"
                    :false="0"
                  ></i-switch>
                  <template v-else>
                    <Tag v-if="item.wechat_state === 1" color="blue">
                      显
                    </Tag>
                    <Tag v-else-if="item.wechat_state === 0" color="red">
                      隐
                    </Tag>
                  </template>
                </span>
              </p>
              <p class="info-item">
                <span class="label">QQ:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.qq"
                  ></Input>
                  <template v-else>{{ item.qq }}</template>
                </span>
                <span class="state">
                  <i-switch
                    v-if="editIndex === index"
                    size="small"
                    v-model="editInfo.qq_state"
                    :true-value="1"
                    :false="0"
                  ></i-switch>
                  <template v-else>
                    <Tag v-if="item.qq_state === 1" color="blue">显</Tag>
                    <Tag v-else-if="item.qq_state === 0" color="red">
                      隐
                    </Tag>
                  </template>
                </span>
              </p>
              <p class="info-item">
                <span class="label">手机:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.mobile"
                  ></Input>
                  <template v-else>{{ item.mobile }}</template>
                </span>
                <span class="state">
                  <i-switch
                    v-if="editIndex === index"
                    size="small"
                    v-model="editInfo.mobile_state"
                    :true-value="1"
                    :false="0"
                  ></i-switch>
                  <template v-else>
                    <Tag v-if="item.mobile_state === 1" color="blue">
                      显
                    </Tag>
                    <Tag v-else-if="item.mobile_state === 0" color="red">
                      隐
                    </Tag>
                  </template>
                </span>
              </p>
              <p class="info-item">
                <span class="label">Email:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.email"
                  ></Input>
                  <template v-else>{{ item.email }}</template>
                </span>
                <span class="state">
                  <i-switch
                    v-if="editIndex === index"
                    size="small"
                    v-model="editInfo.email_state"
                    :true-value="1"
                    :false="0"
                  ></i-switch>
                  <template v-else>
                    <Tag v-if="item.email_state === 1" color="blue">显</Tag>
                    <Tag v-else-if="item.email_state === 0" color="red">
                      隐
                    </Tag>
                  </template>
                </span>
              </p>
            </Col>
          </Row>

          <Divider size="small" dashed></Divider>

          <Row :gutter="16">
            <Col :span="12">
              <p class="info-item">
                <span class="label" style="width: 110px">排序:</span>
                <span class="info">
                  <Input
                    v-if="editIndex === index"
                    type="text"
                    size="small"
                    v-model="editInfo.sort"
                    maxlength="3"
                  ></Input>
                  <template v-else>{{ item.sort }}</template>
                </span>
                <span class="state"></span>
              </p>
            </Col>
            <Col :span="12">
              是否显示:
              <i-switch
                v-if="editIndex === index"
                size="small"
                v-model="editInfo.status"
                :true-value="1"
                :false="0"
              ></i-switch>
              <template v-else>
                <Tag v-if="item.status === 1" color="blue">是</Tag>
                <Tag v-else-if="item.status === 0" color="red">否</Tag>
              </template>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import dataListMixin from '@/mixins/data-list'
import api from '@/api'

export default {
  data() {
    return {
      grid: {
        xl: 8,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24,
      },

      editIndex: -1,
      addNew: false,
      editInfo: {
        name: '',
        remark: '',
        wechat_qrcode: '',
        wechat: '',
        wechat_state: 0,
        qq: '',
        qq_state: 0,
        mobile: '',
        mobile_state: 0,
        email: '',
        email_state: 0,
        sort: 50,
        status: 1,
      },

      submitting: false,
      loading: false,
    }
  },
  mixins: [dataListMixin],
  methods: {
    handleGetDataList() {
      api.customerService.list().then((data) => {
        // console.log(data)
        if (!data) return

        this.dataList = data.customer_service_list || []
      })
    },

    // 单条删除
    handleDelete(id) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `删除后数据不可恢复，确定要删除此项数据？`,
        loading: true,
        onOk: () => {
          if (this.loading) return
          this.loading = true

          api.customerService
            .delete({
              ids: id,
            })
            .then(() => {
              this.loading = false

              this.$Modal.remove()
              this.$Message.success('操作成功')
              // 完成后刷新数据
              // this.handleClearSelect()
              // this.handleChangePage(1)
              this.handleGetDataList()
            })
            .catch(() => {
              this.loading = false
            })
        },
      })
    },

    // 新增
    handleAdd() {
      this.addNew = true
      const row = {
        id: 0,
        name: '',
        remark: '',
        wechat_qrcode: '',
        wechat: '',
        wechat_state: 0,
        qq: '',
        qq_state: 0,
        mobile: '',
        mobile_state: 0,
        email: '',
        email_state: 0,
        sort: 50,
        status: 1,
      }
      this.dataList.unshift(row)
      this.handleEdit(row, 0)
    },

    // 编辑
    handleEdit(row, index) {
      this.editInfo.id = row.id
      this.editInfo.name = row.name
      this.editInfo.remark = row.remark
      this.editInfo.wechat_qrcode = row.wechat_qrcode
      this.editInfo.wechat = row.wechat
      this.editInfo.wechat_state = row.wechat_state
      this.editInfo.qq = row.qq
      this.editInfo.qq_state = row.qq_state
      this.editInfo.mobile = row.mobile
      this.editInfo.mobile_state = row.mobile_state
      this.editInfo.email = row.email
      this.editInfo.email_state = row.email_state
      this.editInfo.sort = row.sort
      this.editInfo.status = row.status
      this.editIndex = index
    },

    // 保存
    handleSave(index) {
      // if (!this.editName || !this.editId || !this.editDepartment) {
      //   this.$Message.error('请填写完整的成员信息！')
      //   return
      // }

      if (this.loading) return
      if (this.submitting) return
      this.loading = true
      this.submitting = true

      const params = {
        id: this.editInfo.id,
        name: this.editInfo.name,
        remark: this.editInfo.remark,
        wechat_qrcode: this.editInfo.wechat_qrcode,
        wechat: this.editInfo.wechat,
        wechat_state: this.editInfo.wechat_state,
        qq: this.editInfo.qq,
        qq_state: this.editInfo.qq_state,
        mobile: this.editInfo.mobile,
        mobile_state: this.editInfo.mobile_state,
        email: this.editInfo.email,
        email_state: this.editInfo.email_state,
        sort: this.editInfo.sort,
        status: this.editInfo.status,
      }
      // console.log(params)
      api.customerService
        .save(params)
        .then(() => {
          this.loading = false

          this.dataList[index].name = this.editInfo.name
          this.dataList[index].remark = this.editInfo.remark
          this.dataList[index].wechat_qrcode = this.editInfo.wechat_qrcode
          this.dataList[index].wechat = this.editInfo.wechat
          this.dataList[index].wechat_state = this.editInfo.wechat_state
          this.dataList[index].qq = this.editInfo.qq
          this.dataList[index].qq_state = this.editInfo.qq_state
          this.dataList[index].mobile = this.editInfo.mobile
          this.dataList[index].mobile_state = this.editInfo.mobile_state
          this.dataList[index].email = this.editInfo.email
          this.dataList[index].email_state = this.editInfo.email_state
          this.dataList[index].sort = this.editInfo.sort
          this.dataList[index].status = this.editInfo.status

          this.editIndex = -1
          this.addNew = false

          this.$Message.success('操作成功')
          this.handleGetDataList()
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.submitting = false
        })
    },

    // 取消编辑
    handleCancel() {
      this.editIndex = -1
      this.addNew = false
    },

    // 取消添加
    handleCancelAdd(index) {
      this.dataList.splice(index, 1)
      this.editIndex = -1
      this.addNew = false
    },

    handleUploadWechatQrcode(file) {
      let params = new FormData()
      params.append('image', file)
      // params.append('width', 72)
      // params.append('height', 72)

      api.upload.image(params).then((data) => {
        this.editInfo.wechat_qrcode = data.url
      })

      return false
    },
  },
  mounted() {
    this.handleGetDataList()
  },
}
</script>

<style lang="scss">
.customer-service-item {
  margin-bottom: 20px;
}
.wechat-qrcode {
  width: 100%;
}
.info-item {
  line-height: 24px;
  display: flex;

  > .label {
    width: 60px;
    text-align: left;
  }
  > .info {
    flex-grow: 1;
  }
  > .state {
    margin-left: 8px;
    width: 40px;
  }
}
</style>
