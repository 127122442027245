export default {
  path: 'permission',
  title: '权限管理',
  auth: 'permission',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/rbac/route',
      title: '前端路由',
      auth: 'rbac-route',
    },
    // {
    //   path: '/rbac/node',
    //   title: '后端节点',
    //   auth:'rbac-node',
    // },
    {
      path: '/rbac/admin',
      title: '管理员',
      auth: 'rbac-admin',
    },
    {
      path: '/rbac/role',
      title: '角色',
      auth: 'rbac-role',
    },
  ],
}
