export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '活动名称',
    key: 'category_name',
    // slot: 'category_id',
    show: true,
  },
  {
    title: '套餐种类',
    key: 'name',
    // slot: 'vip_card_cate_id',
    show: true,
  },
  {
    title: '原价',
    key: 'price',
    slot: 'price',
    show: true,
    width: 150,
  },
  {
    title: '折扣价格',
    key: 'discounted_price',
    slot: 'discounted_price',
    show: true,
    width: 150,
  },
  {
    title: '折扣送天数',
    key: 'add_days',
    slot: 'add_days',
    show: true,
    width: 150,
  },
  {
    title: '首充价格',
    key: 'firstc_discounted_price',
    slot: 'firstc_discounted_price',
    show: true,
    width: 150,
  },
  {
    title: '首充送天数',
    key: 'firstc_add_days',
    slot: 'firstc_add_days',
    show: true,
    width: 150,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
    width: 150,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
