<template>
  <div>
    <div class="layout-page-header">
      <PageHeader title="支付渠道" hidden-breadcrumb />
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt" ref="card">
      <!-- <o-filter ref="filter" @on-submit="handleGetData" /> -->
      <o-table
        ref="table"
        :payment-method-list="paymentMethodList"
        :payment-gateway-list="paymentGatewayList"
        @on-fullscreen="handleFullscreen"
      />
    </Card>
  </div>
</template>
<script>
import screenfull from 'screenfull'
// import oFilter from './filter'
import oTable from './table'
import api from '@/api'

export default {
  name: 'payment-method',
  components: {
    // oFilter,
    oTable,
  },
  data() {
    return {
      paymentMethodList: [],
      paymentGatewayList: [],
    }
  },
  methods: {
    handleGetData() {
      this.handleGetPaymentMethodList()
      this.handleGetPaymentGatewayList()
      this.$refs.table.handleGetDataList()
    },
    handleFullscreen(state) {
      if (state) {
        screenfull.request(this.$refs.card.$el)
      } else {
        screenfull.exit()
      }
    },
    // 取支付方式列表
    handleGetPaymentMethodList() {
      api.paymentMethod
        .list({
          // status: 1,
        })
        .then((data) => {
          if (!data) return
          this.paymentMethodList = data.payment_method_list || []
        })
        .catch(() => {})
    },
    // 取支付网关列表
    handleGetPaymentGatewayList() {
      api.paymentGateway
        .list({
          // status: 1,
        })
        .then((data) => {
          if (!data) return
          this.paymentGatewayList = data.payment_gateway_list || []
        })
        .catch(() => {})
    },
  },
  mounted() {
    this.handleGetData()
  },
}
</script>
