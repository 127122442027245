import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

const pre = 'vip-card-'

export default {
  path: '/vip-card',
  name: 'vip-card',
  redirect: {
    name: `${pre}list`,
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'category',
      name: `${pre}category`,
      meta: {
        ...meta,
        title: '点卡套餐',
      },
      component: () => import('@/views/vip-card/category'),
    },
    {
      path: 'management',
      name: `${pre}management`,
      meta: {
        ...meta,
        title: '活动管理',
      },
      component: () => import('@/views/vip-card/management'),
    },
    {
      path: 'details',
      name: `${pre}details`,
      meta: {
        ...meta,
        title: '折扣编辑',
      },
      component: () => import('@/views/vip-card/details'),
    },
    {
      path: 'list',
      name: `${pre}list`,
      meta: {
        ...meta,
        title: '点卡管理',
      },
      component: () => import('@/views/vip-card/list'),
    },
  ],
}
