<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="种类" prop="category_id">
          <Select
            size="small"
            v-model="params.category_id"
            clearable
            placeholder="请选择种类"
          >
            <Option
              v-for="(item, index) in categoryList"
              :value="item.id"
              :key="index"
            >
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="grid">
        <FormItem label="状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择状态"
          >
            <Option value="1">未使用</Option>
            <Option value="0">已使用</Option>
          </Select>
        </FormItem>
      </Col>
      <Col v-bind="grid" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
          <ButtonGroup size="small" class="ivu-ml-16">
            <Button type="info" @click="handleExport">导出</Button>
          </ButtonGroup>
          <!-- <a v-font="14" class="ivu-ml-8" @click="collapse = !collapse">
            <template v-if="!collapse">
              展开
              <Icon type="ios-arrow-down" />
            </template>
            <template v-else>
              收起
              <Icon type="ios-arrow-up" />
            </template>
          </a> -->
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'
import api from '@/api'
import requestSetting from '@/setting/request'

export default {
  data() {
    return {
      categoryList: [],

      params: {
        category_id: '',
        status: '',
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
  },
  methods: {
    // 取分类列表
    handleGetCategoryList() {
      api.vipCardCategory
        .list({
          status: 1,
        })
        .then((data) => {
          if (!data) return
          this.categoryList = data.vip_card_category_list || []
        })
        .catch(() => {})
    },
    handleSubmit() {
      // console.log(this.params)
      this.params.page = 1
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
    handleExport() {
      api.vipCard
        .export({
          ...this.params,
        })
        .then((data) => {
          if (!data) return

          const url = requestSetting.baseUrl + '/download?file=' + data.file
          window.open(url)
        })
        .catch(() => {})
    },
  },
  mounted() {
    this.handleGetCategoryList()
  },
}
</script>
