<template>
  <i-link
    class="layout-header-logo"
    :class="{ 'layout-header-logo-stick': !isMobile }"
    to="/"
  >
    <img src="@/assets/images/logo-small.png" v-if="isMobile" />
    <img src="@/assets/images/logo.png" v-else-if="headerTheme === 'light'" />
    <img src="@/assets/images/logo-dark.png" v-else />
  </i-link>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HeaderLogo',
  computed: {
    ...mapState('admin/layout', ['isMobile', 'headerTheme']),
  },
}
</script>
