import request from './request'

export default {
  list(data) {
    return request.post('/videoComment/list', data)
  },
  info(data) {
    return request.post('/videoComment/info', data)
  },
  enable(data) {
    return request.post('/videoComment/enable', data)
  },
  disable(data) {
    return request.post('/videoComment/disable', data)
  },
  delete(data) {
    return request.post('/videoComment/delete', data)
  },
}
