const prod = process.env.NODE_ENV === 'production'

export default {
  // 网页标题的后缀
  titleSuffix: '管理后台',
  // 路由模式，可选值为 history 或 hash
  routerMode: 'hash',
  // 应用的基路径
  routerBase: '/',
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
  // 接口请求地址
  apiBaseURL: prod ? '/' : '/',
  // 接口请求返回错误时，弹窗的持续时间，单位：秒
  modalDuration: 3,
  // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
  errorModalType: 'Message',
  // Cookies 默认保存时间，单位：天
  cookieExpires: 1,
}
