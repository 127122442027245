import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  data() {
    return {
      grid: {
        xl: 8,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24,
      },
      collapse: false,
      dateOptions: {
        shortcuts: [
          {
            text: '今天',
            value: () => {
              const date = dayjs(new Date())
                .tz('America/New_York')
                .format('YYYY-MM-DD')
              return [date, date]
            },
          },
          {
            text: '昨天',
            value: () => {
              let date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              date = dayjs(date).tz('America/New_York').format('YYYY-MM-DD')
              return [date, date]
            },
          },
          {
            text: '本周',
            value: () => {
              const date = new Date()
              const start = dayjs(date)
                .day(1)
                .tz('America/New_York')
                .format('YYYY-MM-DD')
              const end = dayjs(date)
                .day(7)
                .tz('America/New_York')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
          {
            text: '本月',
            value: () => {
              const date = new Date()
              const start = dayjs(date)
                .date(1)
                .tz('America/New_York')
                .format('YYYY-MM-DD')
              const end = dayjs(date)
                .add(1, 'month')
                .date(1)
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
        ],
      },
    }
  },
}
