<template>
  <div>
    <h2>基本设置</h2>
    <Form
      v-if="adminInfo"
      ref="form"
      :model="adminInfo"
      :rules="rules"
      label-position="top"
      class="ivu-mt"
    >
      <Row type="flex" :gutter="48">
        <Col v-bind="grid1">
          <FormItem label="头像" prop="avatar">
            <Avatar
              :src="adminInfo.avatar"
              class="setting-account-info-avatar"
              shape="square"
            />
            <Upload
              class="ivu-mt-8"
              action=""
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'git', 'png']"
              :before-upload="handleUploadAvatar"
            >
              <Button icon="md-camera">修改头像</Button>
            </Upload>
          </FormItem>
        </Col>
        <Col v-bind="grid2">
          <FormItem label="昵称" prop="nickname">
            <Input v-model="adminInfo.nickname" placeholder="请输入昵称" />
          </FormItem>
          <FormItem label="邮箱" prop="email">
            <Input v-model="adminInfo.email" placeholder="请输入邮箱" />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="handleSubmit">保存</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      grid1: {
        xl: {
          span: 12,
          order: 2,
        },
        lg: {
          span: 24,
          order: 1,
        },
        md: {
          span: 24,
          order: 1,
        },
        sm: {
          span: 24,
          order: 1,
        },
        xs: {
          span: 24,
          order: 1,
        },
      },
      grid2: {
        xl: {
          span: 12,
          order: 1,
        },
        lg: {
          span: 24,
          order: 2,
        },
        md: {
          span: 24,
          order: 2,
        },
        sm: {
          span: 24,
          order: 2,
        },
        xs: {
          span: 24,
          order: 2,
        },
      },
      adminInfo: {
        email: '',
        nickname: '',
        avatar: '',
      },
      rules: {
        nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            required: true,
            type: 'email',
            message: '请输入正确的邮箱',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    handleGetData() {
      api.admin.info({}).then((data) => {
        this.adminInfo = data.admin || null
      })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            email: this.adminInfo.email,
            nickname: this.adminInfo.nickname,
            avatar: this.adminInfo.avatar,
          }
          api.admin
            .saveInfo(params)
            .then((data) => {
              this.$store.dispatch('admin/user/set', data.admin, { root: true })

              this.$Message.success('信息更新成功!')
            })
            .catch(() => {})
        }
      })
    },
    // 上传前拦截进行手动上传
    handleUploadAvatar(file) {
      // if (file.size > 40960000) {
      //   return false
      // }

      let params = new FormData()
      params.append('image', file)
      params.append('width', 72)
      params.append('height', 72)

      api.upload.adminAvatar(params).then((data) => {
        this.adminInfo.avatar = data.url
      })

      return false
    },
  },
  created() {
    this.handleGetData()
  },
}
</script>

<style lang="scss">
.setting-account-info {
  &-avatar {
    width: 90px;
    height: 90px;
  }
}
</style>
