/**
 * 通用混合
 * */
export default {
  methods: {
    // 当 $route 更新时触发
    // eslint-disable-next-line
    appRouteChange(to, from) {},
  },
}
