import LayoutBasic from '@/layouts/basic'

const meta = {
  auth: true,
}

export default {
  path: '/payment',
  name: 'payment',
  redirect: {
    name: 'payment-order',
  },
  meta,
  component: LayoutBasic,
  children: [
    {
      path: 'method',
      name: 'payment-method',
      meta: {
        ...meta,
        title: '支付方式',
      },
      component: () => import('@/views/payment/method'),
    },
    {
      path: 'gateway',
      name: 'payment-gateway',
      meta: {
        ...meta,
        title: '支付网关',
      },
      component: () => import('@/views/payment/gateway'),
    },
    {
      path: 'channel',
      name: 'payment-channel',
      meta: {
        ...meta,
        title: '支付渠道',
      },
      component: () => import('@/views/payment/channel'),
    },
    {
      path: 'order',
      name: 'payment-order',
      meta: {
        ...meta,
        title: '充值订单',
      },
      component: () => import('@/views/payment/order'),
    },
  ],
}
