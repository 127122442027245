import request from './request'

export default {
  list(data) {
    return request.post('/promotion/list', data)
  },
  info(data) {
    return request.post('/promotion/info', data)
  },
  save(data) {
    return request.post('/promotion/save', data)
  },
  enable(data) {
    return request.post('/promotion/enable', data)
  },
  disable(data) {
    return request.post('/promotion/disable', data)
  },
  // forbidden(data) {
  //   return request.post('/vipCardCategory/forbidden', data)
  // },
  delete(data) {
    return request.post('/promotion/delete', data)
  },
  category(data) {
    return request.post('/promotion/category', data)
  },
  categoryList(data) {
    return request.post('/vipCardCategory/list', data)
  },
}
