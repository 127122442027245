<template>
  <div>
    <Alert type="error">
      <p>导入 mac 列表</p>
      <p>每个 mac 用换行隔开并且不能重复</p>
    </Alert>
    <Form ref="dataInfoForm" :label-width="20" style="margin-bottom: 50px">
      <Input :rows="10" type="textarea" v-model="mac_list" />
    </Form>
    <div class="page-sys-user-drawer-footer">
      <Button type="primary" @click="handleSubmit">确定</Button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      routeList: [],

      mac_list: '',
    }
  },
  methods: {
    handleSubmit() {
      const params = {
        id: this.roleId,
        mac_list: this.mac_list,
      }

      api.tvbox
        .setgenerate(params)
        .then(() => {
          this.$Message.success('添加成功')
          this.$emit('completed', true)
          this.$emit('getDataList', true)

          this.mac_list = ''
        })
        .catch((error) => {
          this.$Message.error(error.message)
        })
    },
  },
}
</script>
