import { read, write, unset } from './_db'

const root = 'session'
const key = 'access'

export default {
  set(value) {
    return write(root, key, value)
  },
  get() {
    return read(root, key)
  },
  clean() {
    return unset(root, key)
  },
}
