<template>
  <div>
    <div
      class="layout-sider-logo"
      :class="{ 'layout-sider-logo-dark': siderTheme === 'dark' }"
    >
      <transition name="fade-quick">
        <i-link to="/" v-show="!hideLogo">
          <img src="@/assets/images/logo-small.png" v-if="menuCollapse" />
          <img
            src="@/assets/images/logo.png"
            v-else-if="siderTheme === 'light'"
          />
          <img src="@/assets/images/logo-dark.png" v-else />
        </i-link>
      </transition>
    </div>
    <Menu
      ref="menu"
      class="layout-menu-side scrollbar-hide"
      :class="{ 'layout-menu-side-collapse': menuCollapse }"
      :theme="siderTheme"
      :accordion="menuAccordion"
      :active-name="activePath"
      :open-names="openNames"
      width="auto"
    >
      <template v-for="(item, index) in filterSider">
        <template v-if="!menuCollapse">
          <o-menu-side-item
            v-if="item.children === undefined || !item.children.length"
            :menu="item"
            :key="index"
          />
          <o-menu-side-submenu v-else :menu="item" :key="index" />
        </template>
        <template v-else>
          <Tooltip
            :content="tTitle(item.title)"
            placement="right"
            v-if="item.children === undefined || !item.children.length"
            :key="index"
          >
            <o-menu-side-item :menu="item" hide-title />
          </Tooltip>
          <o-menu-side-collapse v-else :menu="item" :key="index" top-level />
        </template>
      </template>
    </Menu>
  </div>
</template>
<script>
import oMenuSideItem from './menu-item'
import oMenuSideSubmenu from './submenu'
import oMenuSideCollapse from './menu-collapse'
import tTitle from '../mixins/translate-title'

import { mapState, mapGetters } from 'vuex'

// 元素是否在可视区域
function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export default {
  name: 'MenuSide',
  mixins: [tTitle],
  components: { oMenuSideItem, oMenuSideSubmenu, oMenuSideCollapse },
  props: {
    hideLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('admin/layout', [
      'siderTheme',
      'menuAccordion',
      'menuCollapse',
    ]),
    ...mapState('admin/menu', ['activePath', 'openNames']),
    ...mapGetters('admin/menu', ['filterSider']),
  },
  watch: {
    $route: {
      handler() {
        this.handleUpdateMenuState()
      },
      immediate: true,
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse() {
      this.handleUpdateMenuState()
    },
  },
  methods: {
    handleUpdateMenuState() {
      this.$nextTick(() => {
        if (this.$refs.menu) {
          this.$refs.menu.updateActiveName()
          if (this.menuAccordion) this.$refs.menu.updateOpened()
          // 聚焦当前项
          this.$nextTick(() => {
            const $activeMenu = document.getElementsByClassName(
              'ivu-menu-item ivu-menu-item-active ivu-menu-item-selected',
            )
            if (
              $activeMenu &&
              $activeMenu.length &&
              !isElementInViewport($activeMenu[0])
            ) {
              const activeMenuTop = $activeMenu[0].getBoundingClientRect().top
              const $menu = this.$refs.menu.$el
              setTimeout(() => {
                this.$ScrollTop($menu, {
                  to: activeMenuTop,
                  time: 0,
                })
              }, 300)
            }
          })
        }
      })
    },
  },
}
</script>
