export default {
  path: '/statistics',
  title: '数据统计',
  auth: 'statistics',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/statistics/daily',
      title: '每日数据',
      auth: 'statistics-daily',
    },
  ],
}
