<template>
  <div>
    <Alert type="error">
      <p>选择时请点文字, 点复选框会导致折叠</p>
      <p>如果选择了子菜单, 父级菜单也需要选择, 否则将看不到子菜单</p>
    </Alert>
    <Form ref="dataInfoForm" :label-width="20" style="margin-bottom: 50px">
      <CheckboxGroup size="small" v-model="permissions">
        <Collapse v-for="(topItem, topIndex) in getList(0)" :key="topIndex">
          <Panel>
            <Checkbox :label="topItem.id">
              {{ topItem.name }}
            </Checkbox>
            <div slot="content">
              <Checkbox
                v-for="(subItem, subIndex) in getList(topItem.id)"
                :label="subItem.id"
                :key="subIndex"
              >
                {{ subItem.name }}
              </Checkbox>
            </div>
          </Panel>
        </Collapse>
      </CheckboxGroup>
    </Form>
    <div class="page-sys-user-drawer-footer">
      <Button type="primary" @click="handleSubmit">确定</Button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      routeList: [],

      permissions: [],
    }
  },
  methods: {
    getList(pid) {
      return this.routeList.filter((item) => item.pid === pid)
    },
    handleGetData() {
      if (this.roleId < 1) {
        return
      }
      api.rbac.route.all({}).then((data) => {
        this.routeList = data.rbac_route_list || []
      })

      api.rbac.role
        .routePermissionList({
          id: this.roleId,
        })
        .then((data) => {
          let permissions = []
          if (data.permission_list && data.permission_list.length) {
            data.permission_list.forEach((item) => {
              permissions.push(item.route_id)
            })
          }
          this.permissions = permissions
        })
    },

    handleSubmit() {
      const params = {
        id: this.roleId,
        permissions: this.permissions,
      }

      api.rbac.role.setRoutePermission(params).then(() => {
        this.$Message.success('操作成功')
        this.$emit('completed', true)
        this.permissions = []
      })
    },
  },
}
</script>
