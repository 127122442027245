import request from './request'

export default {
  list(data) {
    return request.post('/paymentChannel/list', data)
  },
  info(data) {
    return request.post('/paymentChannel/info', data)
  },
  save(data) {
    return request.post('/paymentChannel/save', data)
  },
  saveBasic(data) {
    return request.post('/paymentChannel/saveBasic', data)
  },
  saveConfig(data) {
    return request.post('/paymentChannel/saveConfig', data)
  },
  enable(data) {
    return request.post('/paymentChannel/enable', data)
  },
  disable(data) {
    return request.post('/paymentChannel/disable', data)
  },
  delete(data) {
    return request.post('/paymentChannel/delete', data)
  },
}
