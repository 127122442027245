// import i18n from './i18n'
import layout from './layout'
import log from './log'
import menu from './menu'
import passport from './passport'
import user from './user'
import token from './token'
import view from './view'

export default {
  namespaced: true,
  modules: {
    // i18n,
    layout,
    log,
    menu,
    passport,
    user,
    token,
    view,
  },
}
