import { read, write, unset } from './_db'

const root = 'session'
const key = 'token'

const filterToken = (token) => {
  if (Object.keys(token).length === 0) {
    return null
  }
  const timestamp = parseInt(new Date().getTime() / 1000)
  if (token.expired_time <= timestamp) {
    return null
  }
  return token
}

export { filterToken }

export default {
  set(value) {
    return write(root, key, value)
  },
  get() {
    return filterToken(Object.assign({}, read(root, key)))
  },
  clean() {
    return unset(root, key)
  },
}
