export default [
  {
    type: 'selection',
    width: 55,
    show: true,
  },
  {
    title: '编号',
    key: 'id',
    minWidth: 40,
    maxWidth: 100,
    show: true,
  },
  {
    title: '名称',
    key: 'name',
    show: true,
  },
  {
    title: '周期',
    key: 'period',
    slot: 'period',
    show: true,
  },
  {
    title: '购买价格',
    key: 'price',
    slot: 'price',
    show: true,
  },
  {
    title: '兑换所需积分',
    key: 'integral',
    show: true,
  },
  {
    title: '排序',
    key: 'sort',
    show: true,
  },
  {
    title: '状态',
    key: 'status',
    slot: 'status',
    show: true,
  },
  {
    title: '操作',
    key: 'action',
    slot: 'action',
    width: 120,
    align: 'center',
    fixed: 'right',
    show: true,
  },
]
