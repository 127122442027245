<template>
  <Form
    ref="form"
    :model="params"
    col="6"
    :rules="rules"
    :label-width="labelWidth"
    :label-position="labelPosition"
  >
    <Row type="flex">
      <Col v-bind="grid">
        <FormItem label="关键词" prop="mac" label-for="mac">
          <Input
            size="small"
            v-model="params.mac"
            clearable
            placeholder="搜索mac"
          />
        </FormItem>
      </Col>
      <Col v-bind="smallGrid">
        <FormItem label="状态" prop="status">
          <Select
            size="small"
            v-model="params.status"
            clearable
            placeholder="请选择状态"
          >
            <Option value="1">正常</Option>
            <Option value="0">禁用</Option>
          </Select>
        </FormItem>
      </Col>

      <Col v-bind="smallGrid" class="ivu-text-right">
        <FormItem>
          <ButtonGroup size="small">
            <Button type="primary" @click="handleSubmit">查询</Button>
            <Button @click="handleReset">重置</Button>
          </ButtonGroup>
          <!-- <a v-font="14" class="ivu-ml-8" @click="collapse = !collapse">
            <template v-if="!collapse">
              展开
              <Icon type="ios-arrow-down" />
            </template>
            <template v-else>
              收起
              <Icon type="ios-arrow-up" />
            </template>
          </a> -->
        </FormItem>
      </Col>
    </Row>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
import dataFilterMixin from '@/mixins/data-filter'

export default {
  props: {
    platforms: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    categoryList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      grid: {
        xl: 6,
        lg: 6,
        md: 8,
        sm: 24,
        xs: 24,
      },
      smallGrid: {
        xl: 3,
        lg: 4,
        md: 6,
        sm: 8,
        xs: 12,
      },

      params: {
        mac: '',
        status: '',
      },
      rules: {},
    }
  },
  mixins: [dataFilterMixin],
  computed: {
    ...mapState('admin/layout', ['isMobile']),
    labelWidth() {
      return this.isMobile ? undefined : 88
    },
    labelPosition() {
      return this.isMobile ? 'top' : 'right'
    },
    allowCategoryList() {
      let list = []
      for (const i in this.categoryList) {
        if (
          this.params.platform &&
          this.params.platform !== '' &&
          this.categoryList[i].platform !== this.params.platform
        ) {
          continue
        }
        if (
          this.params.type &&
          this.params.type !== '' &&
          this.categoryList[i].type !== this.params.type
        ) {
          continue
        }
        list.push(this.categoryList[i])
      }
      return list
    },
  },
  methods: {
    handleSubmit() {
      // console.log(this.params)
      this.params.page = 1
      this.$emit('on-submit', this.params)
    },
    handleReset() {
      this.$refs.form.resetFields()
      // this.$emit('on-reset')
    },
  },
}
</script>
