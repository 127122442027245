export default {
  path: '/business',
  title: '业务管理',
  auth: 'business',
  header: 'home',
  custom: 'o-icon o-icon-list',
  children: [
    {
      path: '/user/list',
      title: '用户管理',
      auth: 'user-list',
    },
    {
      path: '/tvbox/list',
      title: '盒子管理',
      auth: 'tvbox-list',
    },
    {
      path: '/ad/list',
      title: '广告管理',
      auth: 'ad-list',
    },
    // {
    //   path: '/payment/order',
    //   title: '充值订单',
    //   auth: 'payment-order',
    // },
    {
      path: '/feedback/list',
      title: '用户反馈',
      auth: 'feedback-list',
    },
  ],
}
